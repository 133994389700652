<template>
  <div class="analytics">
    <dashboard-header
      title="pages.dashboard.analytics"
      descriptionLink="https://help.not-me.com/analytics-page"
    />
    <div class="wrapper">
      <div class="analytics-container">
        <div class="header-container">
          <div class="header-text">
            {{ $t("dashboard_sections.analytics.header") }}
          </div>
          <div class="description-text">
            {{ $t("dashboard_sections.analytics.description") }}
          </div>
        </div>
        <div v-if="isAnalyticsEnabled === null"></div>
        <div v-else-if="isAnalyticsEnabled">
          <div class="table-analytics">
            <ui-loading v-if="isLoading" big context="blue" />
            <div v-else>
              <date-picker
                :value="value"
                v-on:changeDate="changeDate"
                :clearDates="clearDates"
              />
              <div
                v-if="
                  can('update report company') ||
                  can('read subsidiary report') ||
                  userRole === 'viewer'
                "
                class="toggle-container"
              >
                <el-checkbox
                  v-if="hasSubcompanies()"
                  v-model="showCompaniesSelector"
                  @click="toggleShowCompaniesSelector"
                  >{{ $t("select_organizations") }}</el-checkbox
                >
                <company-search
                  v-if="showCompaniesSelector"
                  :activeCompanies="activeCompanies"
                  :toggleActiveCompanies="toggleActiveCompanies"
                />
              </div>
              <div class="toggle-container">
                <el-checkbox
                  v-model="showCustomGraphSelector"
                  @click="toggleShowCustomGraphSelector"
                  :disabled="isLoading"
                  >{{ $t("select_custom_graphs") }}</el-checkbox
                >
                <graphs-selector
                  v-if="showCustomGraphSelector"
                  :graphs="graphs"
                  :activeGraphs="activeGraphs"
                  :toggleActiveItem="toggleActiveGraph"
                />
                <!-- <template>
                  <el-checkbox
                    v-model="showCustomFormatSelector"
                    @click="toggleShowCustomFormatSelector"
                    :disabled="isLoading"
                    >{{$t("select_custom_format")}}</el-checkbox
                  >
                  <format-selector v-if="showCustomFormatSelector" :activeFormat="activeFormat" :toggleActiveItem="toggleActiveFormat" />
                </template> -->
              </div>
            </div>
            <ui-button
              context="blue"
              :disabled="value.length < 2"
              :loading="isGeneratingReport"
              @click="getAnalyticsReport"
            >
              {{ $t("export") }}
            </ui-button>
          </div>
        </div>
        <div v-else class="analytics-card">
          <p class="analytics-card--text">
            {{ $t("dashboard_sections.analytics.access_analytics_banner") }}
          </p>
          <ui-button context="red" outline @click="logOutUser">
            {{ $t("authorize") }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader";
import moment from "moment";
import DatePicker from "@/components/DatePicker";
import analyticsApi from "@/api/analyticsApi";
import url_utils from "@/utils/url_utils.js";
import permissions_utils from "@/utils/permissions_utils";
import CompanySearch from "./components/CompanySearch.vue";
import GraphsSelector from "./components/GraphsSelector.vue";
import FormatSelector from "./components/FormatSelector.vue";
import UiButton from "@/components/ui-modules/Buttons/Button";
import UiLoading from "@/components/ui-modules/Loading";

export default {
  name: "Analytics",
  components: {
    DashboardHeader,
    CompanySearch,
    DatePicker,
    GraphsSelector,
    FormatSelector,
    UiButton,
    UiLoading,
  },
  data() {
    return {
      analytics_api_url: "",
      analytics: null,
      isLoading: false,
      isGeneratingReport: false,
      value: [],
      company: null,
      showCompaniesSelector: false,
      showCustomGraphSelector: false,
      showCustomFormatSelector: false,
      activeCompanies: [],
      graphs: [],
      activeGraphs: [],
      activeFormat: "pdf",
      total_analytics: 1,
      isAnalyticsEnabled: null,
      userRole: this.getUserRole(),
    };
  },
  created() {
    this.isLoading = true;
    let token = this.$store.state.auth.token;
    this.isAnalyticsEnabled = url_utils.isNewToken(token);
    this.analytics_api_url = url_utils.getEnvVariable("analytics_api_url");
    this.getGraphs();
  },
  methods: {
    getGraphs() {
      analyticsApi
        .getAnalyticsGraphs(this.analytics_api_url)
        .then((response) => {
          if (response.data.graphs) {
            this.graphs = response.data.graphs;
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toggleActiveGraph(item) {
      if (this.activeGraphs.includes(item)) {
        this.activeGraphs = this.activeGraphs.filter(
          (activeItem) => activeItem !== item,
        );
      } else {
        this.activeGraphs.push(item);
      }
    },
    toggleActiveFormat(item) {
      this.activeFormat = item;
    },
    downloadAnalyticsReport(analytics_report, start_date, end_date) {
      var fileLink = document.createElement("a");
      fileLink.href = analytics_report;
      fileLink.setAttribute(
        "download",
        `notme-analytics-${start_date}-${end_date}.${
          this.activeFormat || "pdf"
        }`,
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
    async getAnalyticsReport() {
      this.isGeneratingReport = true;

      const format = this.showCustomFormatSelector ? this.activeFormat : "pdf";
      const graph_ids = this.showCustomGraphSelector ? this.activeGraphs : null;
      const current_company = JSON.parse(this.$store.state.auth.user).company;
      let current_company_id = null;
      if (current_company) {
        current_company_id = [current_company.id];
      }

      // The user will pick company analytics based on the following conditions:
      // 1. Get active companies if they exist
      // 2. If no active companies, and the user has permission to generate any company analytics,
      //    then get all companies, that's represented by null.
      // 3. Otherwise, get the current company
      const company_ids =
        this.showCompaniesSelector && this.activeCompanies.length > 0
          ? this.activeCompanies
          : permissions_utils.can("generate any company analytics")
            ? null
            : current_company_id;

      const start_date = moment(this.value[0]).format("YYYY-MM-DD");
      const end_date = moment(this.value[1]).format("YYYY-MM-DD");

      try {
        const response = await analyticsApi.getAnalyticsReportAll(
          this.analytics_api_url,
          format,
          graph_ids,
          company_ids,
          start_date,
          end_date,
        );
        this.downloadAnalyticsReport(response.data.file, start_date, end_date);
      } catch (err) {
        if (err.response && err.response.data) {
          this.$message.error(this.$t(err.response.data.description));
        } else {
          this.$message.error(this.$t("error"));
        }
      } finally {
        this.isGeneratingReport = false;
      }
    },
    clearDates() {
      this.value = [];
    },
    can(name) {
      return permissions_utils.can(name);
    },
    hasSubcompanies() {
      return (
        (this.$store.state.subcompanies &&
          this.$store.state.subcompanies?.length > 0) ||
        this.can("invite subscribers to any company")
      );
    },
    toggleActiveCompanies(value) {
      this.activeCompanies = value;
    },
    toggleShowCompaniesSelector() {
      this.showCompaniesSelector = !this.showCompaniesSelector;
    },
    toggleShowCustomGraphSelector() {
      this.showCustomGraphSelector = !this.showCustomGraphSelector;
    },
    toggleShowCustomFormatSelector() {
      this.showCustomFormatSelector = !this.showCustomFormatSelector;
    },
    changeDate(date) {
      this.value = date;
    },
    getUserRole() {
      return permissions_utils.getUserRole();
    },
    logOutUser() {
      this.$confirm(
        this.$t("dashboard_sections.analytics.access_analytics_banner"),
        this.$t("warning"),
        {
          confirmButtonText: this.$t("ok"),
          cancelButtonText: this.$t("cancel"),
          type: "info",
        },
      )
        .then((_) => {
          this.$store.commit("removeAuthData");
          this.$router.push("/login");
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  max-height: 0px;
}
.el-picker-panel__sidebar {
  width: 150px !important;
}
.el-picker-panel__body {
  margin-left: 150px !important;
}

.analytics {
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    justify-content: center;
    .analytics-container {
      max-width: 720px;
      padding-bottom: 40px;
    }
  }
  .toggle-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    .el-checkbox {
      margin-bottom: 16px;
      .el-checkbox__label {
        font-family: "Campton-Medium";
        font-size: 16px;
        color: $ui-grey;
      }
    }
    .el-checkbox.is-checked {
      .el-checkbox__inner {
        background-color: $ui-green;
        border-color: $ui-green;
      }
      .el-checkbox__label {
        p {
          color: $ui-dark_blue;
        }
      }
    }
  }

  .header-container {
    .header-text {
      color: $ui-text;
      font-family: "Campton-Bold";
      font-size: 28px;
      text-align: left;
      padding-bottom: 8px;
    }
    .description-text {
      color: $ui-subtitle;
      font-family: "Campton-Medium";
      font-size: 16px;
      text-align: left;
    }
  }
  .table-analytics {
    text-align: left;
    .el-date-editor {
      width: 100%;
      justify-content: center;
      height: 36px;
    }
    .range-label {
      font-family: "Campton-Medium" !important;
      color: $ui-dark_grey;
      text-transform: uppercase;
      letter-spacing: 0.75px;
      display: block;
      margin: 0 0 8px;
      font-size: 12px;
      font-family: "Campton-Medium";
      line-height: 1.5em;
    }
  }

  .analytics-card {
    margin-top: 20px;
    padding: 16px;
    background-color: $ui-lighter_grey;
    border-radius: 12px;
    .analytics-card--text {
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
}
</style>
