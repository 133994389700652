<template>
  <div class="report-card">
    <div class="report-card-header" v-if="title">
      <div class="report-card-header--title">
        <img v-if="icon" :src="require(`@/assets/${icon}.png`)" height="40" />
        <h5 :class="icon ? 'next-icon' : ''">{{ $t(title) }}</h5>
        <information-popover
          v-if="description"
          :description="description"
          :descriptionLink="description_link"
        >
          <i class="fas fa-info-circle info-circle" />
        </information-popover>
        <ui-tag
          v-if="count > 0"
          context="grey"
          subtle
          circle
          small
          class="report-card-header--count"
        >
          {{ count }}
        </ui-tag>
      </div>
      <div class="report-card-header--right-content">
        <ui-button
          v-if="button_icon"
          class="report-card-header-btn"
          smallSquare
          hoverShadow
          outline
          @click="$emit('action')"
        >
          <i :class="[button_icon]" aria-hidden="true" />
        </ui-button>
      </div>
    </div>
    <ul class="report-card-list">
      <slot />
    </ul>
  </div>
</template>

<script>
import InformationPopover from "@/components/InformationPopover";
import UiTag from "@/components/ui-modules/Tag";
import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "ReportCard",
  components: {
    InformationPopover,
    UiTag,
    UiButton,
  },
  props: {
    title: {
      type: String,
    },
    count: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
    },
    button_icon: {
      type: String,
    },
    description: {
      type: String,
    },
    description_link: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.report-card {
  text-align: left;
  padding-bottom: 20px;

  .report-card-header {
    display: flex;
    align-items: center;
    padding: 0 0 20px 0;
    flex-direction: row;
    justify-content: space-between;

    .report-card-header--title {
      display: flex;
      flex-direction: row;
      align-items: center;

      .report-card-header--count {
        margin-left: 10px;
        font-family: "Campton-Bold";
        margin-bottom: 4px;
        font-size: 14px;
        color: $ui-text;
      }
    }

    .report-card-header--right-content {
      .report-card-header-btn {
        margin-left: 10px;
        height: 28px;
        width: 28px;
        padding: 6px;
      }
    }

    h5 {
      color: $ui-text;
      font-size: 17px;
      font-family: "Campton-Bold" !important;
      margin-bottom: 0;
    }

    .next-icon {
      margin-left: 20px;
    }
  }

  .title {
    font-size: 17px;
    color: $ui-subtitle;
    font-family: "Campton-Bold";
  }

  .subtitle {
    color: $ui-subtitle;
    font-size: 15px;
    font-family: "Campton-Medium";
    margin: 0;
    margin-top: 8px;
  }

  .resolved_subtitle {
    color: $ui-green;
  }

  .info-circle {
    color: $ui-dark_grey;
    align-self: center;
    margin-left: 10px;
    padding-top: 4px;
    font-size: 16px;
  }

  .report-card-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li + li {
      margin-top: 20px;
    }
    .text-details {
      margin-bottom: 0px;
    }
  }
}
</style>
