const urls = {
  BASE: "dashboard/",
  ANALYTICS_BASE: "analytics/",
  USER: "user",
  USERS: "users",
  LOGIN: "login",
  LOGOUT: "logout",
  PERMISSIONS: "permissions",
  RECOVER: "recover",
  SIGNUP: "signup",
  REPORTS: "reports",
  REPORT: "report",
  REPORTING: "reporting",
  VICTIMS: "victims",
  HARASSERS: "harassers",
  BEHAVIORS: "behaviors",
  LOCATIONS: "locations",
  PLACES: "places",
  INFO: "info",
  OUTCOME: "outcome",
  COMPANY: "company",
  COMPANIES: "companies",
  INVITES: "invites",
  MFA_BASE: "challenge/",
  MFA: "2fa",
  PHONE: "phone",
  VERIFY: "verify",
  FEEDS: "feeds",
  CHAT: "chat",
  GENERATE: "generate",
  OFFSET: "offset",
  LIMIT: "limit",
  GRAPHS: "graphs",
  ALL: "all",
  ADMIN: "admin",
};

export default {
  DASHBOARD_URL: `${urls.BASE}`,
  LOGIN_URL: `${urls.BASE}${urls.USER}/${urls.LOGIN}`,
  LOGOUT_URL: `${urls.BASE}${urls.USER}/${urls.LOGOUT}`,
  SIGNUP_URL: `${urls.BASE}${urls.USER}/${urls.SIGNUP}`,
  USER_PERMISSIONS_URL: `${urls.BASE}${urls.USER}/${urls.PERMISSIONS}`,
  USER_RECOVER_URL: `${urls.BASE}${urls.USER}/${urls.RECOVER}`,
  REPORTS_URL: `${urls.BASE}${urls.REPORTS}`,
  REPORT_URL: `${urls.BASE}${urls.REPORT}`,
  VICTIMS_URL: `${urls.BASE}${urls.VICTIMS}`,
  HARASSERS_URL: `${urls.BASE}${urls.HARASSERS}`,
  BEHAVIORS_URL: `${urls.BASE}${urls.BEHAVIORS}`,
  LOCATIONS_URL: `${urls.BASE}${urls.LOCATIONS}`,
  PLACES_URL: `${urls.BASE}${urls.PLACES}`,
  LOCATIONS_INFO_URL: `${urls.BASE}${urls.LOCATIONS}/${urls.INFO}`,
  COMPANY_URL: `${urls.BASE}${urls.COMPANY}/`,
  COMPANY_USERS_URL: `${urls.BASE}${urls.COMPANY}/${urls.USERS}`,
  COMPANY_USERS_INVITES_URL: `${urls.BASE}${urls.COMPANY}/${urls.USERS}/${urls.INVITES}`,
  COMPANIES_URL: `${urls.BASE}${urls.COMPANIES}`,
  USER_URL: `${urls.BASE}${urls.USER}`,
  VERIFY_MAIL_URL: `${urls.USER}/${urls.VERIFY}`,
  MFA_URL: `${urls.BASE}${urls.MFA_BASE}${urls.MFA}`,
  PHONE_URL: `${urls.BASE}${urls.MFA_BASE}${urls.PHONE}`,
  PHONE_VERIFY_URL: `${urls.BASE}${urls.MFA_BASE}${urls.PHONE}/${urls.VERIFY}`,
  NEWSFEED_URL: `${urls.BASE}${urls.FEEDS}`,
  CHAT_LOGIN_URL: `${urls.LOGIN}`,
  REPORT_RESOLUTION_URL: `${urls.OUTCOME}`,
  FIREBASE_LOGIN_URL: `${urls.CHAT}/${urls.REPORTS}/`,
  ANALYTICS_GRAPHS: `${urls.ANALYTICS_BASE}${urls.GRAPHS}`,
  ANALYTICS_REPORT: `${urls.ANALYTICS_BASE}${urls.ALL}/`,
  ANALYTICS_ADMIN_REPORT: `${urls.ANALYTICS_BASE}${urls.ADMIN}/${urls.ALL}`,
  REPORTING_REPORT_URL: `${urls.REPORTING}/v1/${urls.REPORTS}`,
  GENERATE_URL: `${urls.GENERATE}`,
};
