<template>
  <textarea
    v-model="model"
    class="report-chat-text-area"
    :style="computedStyles"
    ref="textarea"
    @focus="resize"
    @keydown.enter.exact.prevent="$emit('enterAction')"
    @keydown.enter.shift.exact="$emit('shiftEnterAction')"
  ></textarea>
</template>
<script>
export default {
  name: "ReportChatTextArea",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    minHeight: {
      type: [Number],
      default: null,
    },
    maxHeight: {
      type: [Number],
      default: null,
    },
  },
  data() {
    return {
      model: null,
      maxHeightScroll: false,
      height: "auto",
    };
  },
  computed: {
    computedStyles() {
      return {
        resize: "none",
        height: this.height,
        overflow: this.maxHeightScroll ? "auto" : "hidden",
      };
    },
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
    model(newVal) {
      this.$nextTick(this.resize);
      this.$emit("input", newVal);
    },
    minHeight() {
      this.$nextTick(this.resize);
    },
    maxHeight() {
      this.$nextTick(this.resize);
    },
  },
  methods: {
    resize() {
      this.height = "auto";
      this.$nextTick(() => {
        let contentHeight = this.$refs.textarea.scrollHeight + 1;
        if (this.minHeight) {
          contentHeight =
            contentHeight < this.minHeight ? this.minHeight : contentHeight;
        }
        if (this.maxHeight) {
          if (contentHeight > this.maxHeight) {
            contentHeight = this.maxHeight;
            this.maxHeightScroll = true;
          } else {
            this.maxHeightScroll = false;
          }
        }
        this.height = `${contentHeight}px`;
      });
      return this;
    },
  },
  created() {
    this.model = this.value;
  },
  mounted() {
    this.resize();
  },
};
</script>
<style lang="scss">
.report-chat-text-area {
  display: block;
  flex: 1 1 auto;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border: 0;
  outline-width: 0px;
}
</style>
