<template>
  <report-card title="report_information">
    <report-user
      style="margin-bottom: 20px"
      :user="report.user"
      :is_anonymous="report.is_anonymous"
      :is_report_header="true"
    />
    <p class="subtitle">
      <i class="fas fa-calendar-alt fa-fw"></i>
      {{ this.beautifyDate(report.created_at) }}
    </p>
    <p v-if="report.submitted_at" class="subtitle">
      <i class="fas fa-paper-plane fa-fw"></i>
      {{ this.beautifyDate(report.submitted_at) }}
    </p>
    <p v-if="report.resolved_at" class="subtitle">
      <i class="fas fa-clipboard-check fa-fw"></i>
      {{ this.beautifyDate(report.resolved_at) }}
    </p>
    <p v-if="report.resolved_at" class="subtitle resolved_subtitle">
      <i class="fas fa-stopwatch fa-fw"></i>
      {{ generateTimeToResolution(report.resolved_at, report.submitted_at) }}
    </p>
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard";
import ReportUser from "./ReportUser";

import moment from "moment";

export default {
  name: "ReportInformation",
  components: {
    ReportCard,
    ReportUser,
  },
  props: {
    report: {
      type: Object,
    },
  },
  methods: {
    beautifyDate(date) {
      return moment(date).format("lll");
    },
    generateTimeToResolution(resolved_at, submitted_at) {
      const start = moment(submitted_at);
      const end = moment(resolved_at);
      const duration = moment.duration(end.diff(start));
      const data = {
        "year(s)": duration.years(),
        "month(s)": duration.months(),
        "day(s)": duration.days(),
        "hour(s)": duration.hours(),
        "minute(s)": duration.minutes() + (duration.seconds() > 0 ? 1 : 0),
      };
      let finalString = "";
      for (const [key, value] of Object.entries(data)) {
        if (value > 0) {
          if (finalString !== "") {
            finalString = finalString.concat(", ");
          }
          finalString = finalString.concat(`${value} ${this.$t(key)}`);
        }
      }
      finalString = finalString.concat(".");
      return finalString;
    },
  },
};
</script>

<style lang="scss">
.btn-shape {
  border-radius: 20px;
  border-width: 0px;
  height: 100%;
  margin-left: 10px;
  text-align: right;
  &:hover {
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
  }
}
</style>
