<template>
  <transition name="dropdown-container-fade">
    <div
      v-if="showDropdown"
      class="dropdown-container"
      :style="{ '--positionX': `${positionX}px` }"
    >
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "DropdownContainer",
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    positionX: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.dropdown-container-fade-enter-active,
.dropdown-container-fade-leave-active {
  transition: opacity 0.25s;
}
.dropdown-container-fade-enter,
.dropdown-container-fade-leave-to {
  opacity: 0;
}

.dropdown-container {
  display: block;
  z-index: 2;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  min-width: 180px;
  max-width: 300px;
  border-radius: 10px;
  background-color: $ui-card;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;
  overflow: auto;
  max-height: 400px;
  transform: translateX(var(--positionX));
  ul {
    margin: 0;
    padding: 10px 0px;
    list-style: none;
  }
}
</style>
