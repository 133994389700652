<template>
  <div class="report-file-upload">
    <span class="report-file-upload--icon">
      <i :class="['far', 'fa-2x', getFileTypeIcon()]"></i>
    </span>
    <span class="report-file-upload--title">
      {{ report_file.name }}
    </span>
    <span class="report-file-upload--remove-icon" @click="$emit('remove-file')">
      <i class="fas fa-trash-alt"></i>
    </span>
  </div>
</template>

<script>
import report_utils from "@/utils/report_utils";

export default {
  name: "ReportFileUpload",
  props: {
    report_file: {
      type: File,
    },
  },
  methods: {
    getFileTypeIcon() {
      let file_type = this.report_file.type;
      return report_utils.getFileTypeIcon(file_type);
    },
  },
};
</script>

<style lang="scss">
.report-file-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  .report-file-upload--icon {
    padding-right: 1rem;
  }

  .report-file-upload--title {
    word-break: break-word;
  }

  .report-file-upload--remove-icon {
    color: $ui-red;
    justify-content: center;
    text-align: center;
    vertical-align: bottom;
    margin-left: auto;
    margin-right: 0;
    align-self: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $ui-dark_red;
    }
  }
}
</style>
