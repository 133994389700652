<template>
  <div class="timeline-event">
    <div class="timeline-icon-wrapper">
      <div class="timeline-icon">
        <i class="fas fa-clipboard" />
      </div>
    </div>
    <div class="timeline-content-container">
      <div class="content">
        <div class="title-container">
          <span class="inline-header">
            {{ getUpdatedBy() }}
          </span>
          <div
            v-html="this.getInfoTranslation()"
            class="translation inline-header"
          />
          <div class="date">
            {{ item.updated_on.format("LLL") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import report_utils from "@/utils/report_utils";

export default {
  name: "TimelineInfo",
  components: {},
  props: {
    user: {
      type: Object,
    },
    users: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getStatus(status_id) {
      return this.$t(report_utils.getStatusTitle(Number(status_id)));
    },
    getScore(score) {
      return this.$t(score);
    },
    getStatusColor(status_id) {
      return report_utils.getStatusColor(Number(status_id));
    },
    getScoreColor(score) {
      return report_utils.getScoreColorByTitle(score);
    },
    getInfoTranslation() {
      if (this.item.prev_status_id) {
        if (this.item.initial_status) {
          return this.$t("initial_report_status", {
            status: `<span style="color: rgb(${this.getStatusColor(
              this.item.status_id,
            )});">${this.getStatus(this.item.status_id)}</span>`,
          });
        }
        return this.$t("updated_status_from_to", {
          previous_status: `<span style="color: rgb(${this.getStatusColor(
            this.item.prev_status_id,
          )});">${this.getStatus(this.item.prev_status_id)}</span>`,
          new_status: `<span style="color: rgb(${this.getStatusColor(
            this.item.status_id,
          )});">${this.getStatus(this.item.status_id)}</span>`,
        });
      } else if (this.item.prev_score) {
        if (this.item.initial_score) {
          return this.$t("initial_report_priority", {
            priority: `<span style="color: rgb(${this.getScoreColor(
              this.item.score,
            )});">${this.getScore(this.item.score)}</span>`,
          });
        }
        return this.$t("updated_priority_from_to", {
          previous_priority: `<span style="color: rgb(${this.getScoreColor(
            this.item.prev_score,
          )});">${this.getScore(this.item.prev_score)}</span>`,
          new_priority: `<span style="color: rgb(${this.getScoreColor(
            this.item.score,
          )});">${this.getScore(this.item.score)}</span>`,
        });
      } else {
        return "";
      }
    },
    getUpdatedBy() {
      return this.user
        ? this.user.firstname + " " + this.user.lastname
        : this.$t("anonymous");
    },
  },
};
</script>

<style lang="scss"></style>
