import api from "./currentApi";
import constants from "./constants";

const getCompany = (params) => {
  return api.request_logged(constants.COMPANY_URL, params);
};

const getCompanyById = (id) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_logged(url);
};

const updateCompany = (params) => {
  return api.request_logged_put(constants.COMPANY_URL, params);
};

const updateSubcompany = (params, id) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_logged_put(url, params);
};

const updateCompanyLogo = (formData) => {
  return api.request_logged_post_multipart(constants.COMPANY_URL, formData);
};

const updateSubcompanyLogo = (formData, id) => {
  const url = id ? constants.COMPANY_URL + id : constants.COMPANY_URL;
  return api.request_logged_post_multipart(url, formData);
};

const getCompanyUsers = (params) => {
  return api.request_logged(constants.COMPANY_USERS_URL + "?role=user", params);
};

const getCompanySubscribers = (params) => {
  return api.request_logged(
    constants.COMPANY_USERS_URL + "?role=subscriber&limit=100",
    params,
  );
};

const inviteCompanySubscribers = (params) => {
  return api.request_logged_post(constants.COMPANY_USERS_INVITES_URL, params);
};

const getCompanyDashboardInvited = (params) => {
  return api.request_logged(constants.COMPANY_USERS_INVITES_URL, params);
};

const getCompanies = (params) => {
  return api.request_logged(constants.COMPANIES_URL, params);
};

const deleteCompanyDashboardInvited = (id) => {
  return api.request_logged_delete(
    constants.COMPANY_USERS_INVITES_URL + "/" + id,
  );
};

const getCompanyDashboardUsers = (params) => {
  let roles = ["admin", "manager", "subscriber", "editor", "viewer"];
  return api.request_logged(constants.COMPANY_USERS_URL, {
    ...params,
    roles: roles,
  });
};

const kickCompanyUser = (id) => {
  return api.request_logged_delete(constants.COMPANY_USERS_URL + "/" + id);
};

const setRole = (id, role) => {
  return api.request_logged_post(constants.COMPANY_USERS_URL + "/" + id, {
    role: role,
  });
};

export default {
  getCompany,
  getCompanyById,
  updateCompany,
  updateSubcompany,
  updateCompanyLogo,
  updateSubcompanyLogo,
  getCompanyUsers,
  getCompanySubscribers,
  inviteCompanySubscribers,
  getCompanyDashboardInvited,
  getCompanies,
  deleteCompanyDashboardInvited,
  kickCompanyUser,
  getCompanyDashboardUsers,
  setRole,
};
