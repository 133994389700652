<template>
  <div class="user-dropdown">
    <el-dropdown trigger="click">
      <div class="el-dropdown-link info-button">
        <i class="fas fa-chevron-up info-button-icon"></i>
      </div>
      <el-dropdown-menu slot="dropdown" class="user-dropdown-menu">
        <el-dropdown-item
          v-if="
            this.getUserRole() !== 'viewer' &&
            (this.can('read company profile') ||
              this.can('read company members'))
          "
          class="dropdown-other-item"
          @click.native="goToSettings"
          ><i class="fas fa-cog" />
          {{ $t("pages.settings.settings") }}</el-dropdown-item
        >
        <el-dropdown-item
          v-if="this.can('read profile')"
          class="dropdown-other-item"
          @click.native="goToProfile"
          ><i class="fas fa-user" />
          {{ $t("pages.settings.profile") }}</el-dropdown-item
        >
        <el-dropdown-item
          class="dropdown-other-item"
          divided
          @click.native="logout"
          ><i class="fas fa-sign-out-alt" />
          {{ $t("pages.log_out") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <div class="user-container">
      <company-logo
        :company="JSON.parse(this.$store.state.auth.user).company"
      />
      <div class="user-info">
        <h6 class="profile-name">
          {{
            JSON.parse(this.$store.state.auth.user).firstname +
            " " +
            JSON.parse(this.$store.state.auth.user).lastname
          }}
        </h6>
        <p class="profile-role">{{ $t(this.$store.state.auth.role.title) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyLogo from "@/components/CompanyLogo";
import permissions_utils from "@/utils/permissions_utils";
import url_utils from "@/utils/url_utils";

export default {
  name: "UserDropdown",
  components: {
    CompanyLogo,
  },
  methods: {
    goToSettings() {
      window.open(`https://${url_utils.getAppUrl()}/settings/general`, "_self");
    },
    goToProfile() {
      window.open(`https://${url_utils.getAppUrl()}/settings/profile`, "_self");
    },
    logout() {
      this.$confirm(this.$t("logout_confirmation"), this.$t("pages.log_out"), {
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("cancel"),
        type: "info",
      })
        .then((_) => {
          this.$store.commit("removeAuthData");
          this.$router.push("/login");
        })
        .catch((_) => {});
    },
    can(name) {
      return permissions_utils.can(name);
    },
    getUserRole() {
      return permissions_utils.getUserRole();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  display: block;
  position: initial;
  .user-container {
    position: relative;
    padding: 5px 20px 5px 5px;
    margin: 5px 20px 5px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: $ui-subtitle;
    transition:
      color 0.5s,
      background-color 1s,
      font-weight 0.1s ease;
    .company-logo {
      object-fit: contain;
      border-radius: 6px;
      -webkit-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
      height: 40px;
      width: 40px;
      margin-right: 16px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .user-info {
      overflow: hidden;
    }
  }
  .avatar-circle {
    @include avatar(45px);
  }
  .profile-name {
    font-family: "Campton-Bold";
    margin: 0;
    color: $ui-text;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
    text-align: left;
  }
  .profile-role {
    font-family: "Campton-Book";
    color: $ui-subtitle;
    font-size: 14px;
    margin-bottom: 0px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .el-dropdown {
    position: inherit;
    display: inherit;
  }
  .info-button {
    font-family: "Avenir";
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 12.5px;
    top: 17px;
    right: 15px;
    background-color: $ui-lighter_grey;
    z-index: 10;
    &:hover {
      -webkit-box-shadow: $widget-shadow;
      -moz-box-shadow: $widget-shadow;
      box-shadow: $widget-shadow;
    }

    .info-button-icon {
      font-size: 10px;
      text-align: center;
      display: inline;
      line-height: normal;
      vertical-align: baseline;
      top: 25%;
      color: $ui-text;
    }
  }
}
.user-dropdown-menu {
  text-align: left;
  .dropdown-profile-item {
    cursor: default;
  }
  .dropdown-profile-item:hover {
    background: none;
  }
  .dropdown-other-item:hover {
    background: $ui-light_blue;
    color: white;
  }
}
</style>
