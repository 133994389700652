<template>
  <div class="module-selector">
    <label class="label">{{ label }}</label>
    <el-select
      v-model="value"
      :placeholder="placeholder"
      @change="handle_change"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="$t(item.label)"
        :value="item.value"
      >
        <i
          v-if="item.icon"
          :class="['fas', 'module-selector--item-icon', item.icon]"
          aria-hidden="true"
        />
        <span class="module-selector--label">{{ $t(item.label) }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "module-selector",
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    model: {
      type: String,
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {
      value: this.model,
    };
  },
  methods: {
    handle_change(command) {
      this.$emit("update:model", command);
    },
  },
};
</script>

<style lang="scss">
.module-selector--item-icon {
  color: $ui-text;
  margin-right: 10px;
}

.module-selector {
  flex-direction: column;
  margin-bottom: 16px;

  .label {
    margin-bottom: 0.2em;
    font-family: "Campton-Medium";
    font-size: 16px;
    color: $ui-text;
  }

  .module-selector--label {
    color: $ui-text;
  }

  .el-select {
    display: inherit;

    .el-input {
      font-size: inherit;
    }

    .el-input__inner {
      -webkit-appearance: none;
      outline: none;
      border-radius: 0px;
      border: 1px solid $ui-light_grey;
      border-radius: 6px;
      background-color: $ui-card;
      color: $ui-text;
      padding: 1em;
      width: 100%;
      height: 3em;
      &:focus {
        outline: none;
        border: 1px solid $ui-light_blue;
      }
    }
  }
}
</style>
