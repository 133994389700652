<template>
  <li :class="isInfo ? 'info' : isSubscriber ? 'subscriber' : 'receiver'">
    <el-tooltip
      :content="getDate()"
      :open-delay="500"
      :disabled="isInfo"
      :placement="isSubscriber ? 'top-end' : 'top-start'"
      effect="dark"
    >
      <p class="text-content">{{ content }}</p>
    </el-tooltip>
  </li>
</template>

<script>
import moment from "moment";

export default {
  name: "ReportChatBubble",
  props: {
    isSubscriber: {
      type: Boolean,
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
    },
    date: {
      type: Number,
    },
  },
  methods: {
    getDate() {
      return moment.unix(this.date).format("lll");
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  display: inline-block;
  clear: both;
  padding: 10px;
  border-radius: 25px;
  margin-bottom: 4px;
  max-width: 220pt;

  .text-content {
    margin: 0px;
  }

  .date {
    margin: 0px;
    font-size: 12px;
  }
}

.receiver {
  float: left;
  text-align: left;
  background: #eee;
  color: #000;
}

.subscriber {
  float: right;
  text-align: right;
  background: $ui-red;
  color: #fff;
}

.info {
  text-align: center;
  background: #eee;
  color: $ui-red;
  max-width: 100% !important;
  width: 100% !important;
  margin: 10px 0px 10px 0px;
  font-size: 14px;
  border-radius: 10px;
}
</style>
