var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-tooltip',{attrs:{"effect":"dark","disabled":!_vm.user,"placement":"bottom"}},[(_vm.user)?_c('div',{staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.user.firstname + " " + _vm.user.lastname)),_c('br'),_vm._v(_vm._s(_vm.$t(_vm.user.role))+" ")]):_vm._e(),_c('div',{staticClass:"avatar-circle",style:({
      '--color': _vm.title
        ? '#637482'
        : _vm.user
          ? _vm.generateColorId(_vm.user.id)
          : _vm.generateColorId(_vm.id),
    })},[(_vm.user)?_c('span',{staticClass:"initials"},[_vm._v(_vm._s(_vm.getInitial(_vm.user.firstname + " " + _vm.user.lastname)))]):_c('span',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm.title || _vm.getInitial(_vm.name)))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }