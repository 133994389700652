<template>
  <div class="dashboard-cell">
    <div
      :style="{ '--color': getStatusColor(report.status_id) }"
      class="report-cell"
    >
      <div class="report-container">
        <!-- <div class="left-checkbox">
        <input
          :id="report.id"
          v-model="itemsSelected[report.id]"
          type="checkbox">
      </div> -->
        <router-link
          :to="{ path: '/reports/' + report.id }"
          style="width: 100%"
        >
          <!--calc(100% - 71px);">-->
          <div class="row report-row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 my-auto">
              <div class="report-title-container">
                <p
                  v-if="report.status_id === 3"
                  class="report-title"
                  style="font-family: &quot;Campton-Medium&quot;"
                >
                  {{ $t("report_id", { id: report.id }) }}
                </p>
                <p v-else class="report-title">
                  {{ $t("report_id", { id: report.id }) }}
                </p>
                <ui-tag
                  v-if="report.files_count > 0"
                  context="grey"
                  subtle
                  circle
                  small
                  class="report-icon"
                  style="margin-bottom: 4px"
                >
                  {{ report.files_count }}
                  <i class="fas fa-paperclip icon-info"></i>
                </ui-tag>
              </div>
              <media :query="{ minWidth: 768 }">
                <ui-tag
                  v-if="report.tags.length === 0"
                  :context="getStatusColorName(report.status_id)"
                  subtle
                  circle
                  small
                  >{{ getStatus(report.status_id) }}</ui-tag
                >
                <div v-else class="tags-container">
                  <ui-tag
                    :context="getStatusColorName(report.status_id)"
                    subtle
                    circle
                    small
                    >{{ getStatus(report.status_id) }}</ui-tag
                  >
                  <ui-tag
                    v-for="tag in report.tags.slice(0, 2)"
                    :key="tag.slug"
                    :context="getTagColorName(tag)"
                    subtle
                    round
                    small
                  >
                    {{ $t(`tags_info.${tag.slug}`) }}
                  </ui-tag>
                  <el-tooltip
                    effect="dark"
                    placement="bottom"
                    v-if="report.tags.length > 2"
                  >
                    <div slot="content">
                      <a v-for="tag in report.tags" :key="tag.slug">
                        {{ $t(`tags_info.${tag.slug}`) }}<br />
                      </a>
                    </div>
                    <ui-tag context="grey" subtle round small>
                      {{ `+${report.tags.length - 2}` }}
                    </ui-tag>
                  </el-tooltip>
                </div>
              </media>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-6 my-auto">
              <p class="right-text username-text">
                {{ userFormatter() }}
                <el-tooltip
                  effect="dark"
                  placement="bottom"
                  v-if="isReporterExecutive()"
                >
                  <div slot="content">
                    {{ $t("executive_report") }}
                  </div>
                  <i class="fas fa-id-badge" />
                </el-tooltip>
              </p>
              <p
                v-if="
                  $store.state.auth.role.title.localeCompare('super admin') ===
                    0 ||
                  ($store.state.subcompanies &&
                    $store.state.subcompanies.length > 0)
                "
                class="right-text company-name"
              >
                <company-logo
                  style="display: inline-block"
                  :company="report.company"
                  :size="30"
                />
                {{ report.company ? report.company.name : "" }}
              </p>
            </div>
            <media :query="{ minWidth: 768 }">
              <div class="col-lg-3 col-md-3 col-sm-6 col-6 my-auto">
                <ui-tag
                  v-if="can('view report score')"
                  :context="getScoreColorName(report.score)"
                  subtle
                  circle
                  small
                >
                  {{ getScore(report.score) }}
                </ui-tag>

                <assignee-initial
                  class="assignees"
                  :assignees="report.assignees"
                />
              </div>
            </media>
            <media :query="{ minWidth: 768 }">
              <div class="col-lg-3 col-md-3 col-sm-6 col-6 my-auto">
                <p>
                  {{
                    report.submitted_at
                      ? moment(report.submitted_at).format("lll")
                      : "-"
                  }}
                </p>
              </div>
            </media>
            <media :query="{ maxWidth: 767 }">
              <div class="col-lg-3 col-md-3 col-sm-12 col-12 my-auto">
                <ui-tag
                  :context="getStatusColorName(report.status_id)"
                  subtle
                  circle
                  small
                >
                  {{ getStatus(report.status_id) }}
                </ui-tag>
                <ui-tag
                  v-if="can('view report score')"
                  :context="getScoreColorName(report.score)"
                  subtle
                  circle
                  small
                >
                  {{ getScore(report.score) }}
                </ui-tag>
                <assignee-initial
                  class="assignees-small"
                  :assignees="report.assignees"
                />
                <el-tooltip
                  v-if="report.tags.length > 0"
                  effect="dark"
                  placement="bottom"
                >
                  <div slot="content">
                    <a v-for="tag in report.tags" :key="tag.slug">
                      {{ $t(`tags_info.${tag.slug}`) }}<br />
                    </a>
                  </div>
                  <ui-tag context="grey" subtle round small>
                    <div class="tag-icon-container">
                      {{ report.tags.length }}
                      <i class="fas fa-tag icon-info"></i>
                    </div>
                  </ui-tag>
                </el-tooltip>
                <p
                  style="display: inline-block; float: right"
                  class="right-text"
                >
                  {{ moment(report.submitted_at).format("l") }}
                </p>
              </div>
            </media>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import AssigneeInitial from "@/components/AssigneeInitial";
import CompanyLogo from "@/components/CompanyLogo";
import Media from "vue-media";
import report_utils from "@/utils/report_utils";
import tag_utils from "@/utils/tag_utils";
import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportCell",
  components: {
    UiTag,
    AssigneeInitial,
    CompanyLogo,
    Media,
  },
  props: ["report", "itemsSelected"],
  methods: {
    getStatus(status_id) {
      return this.$t(report_utils.getStatusTitle(status_id));
    },
    getStatusColor(status_id) {
      return report_utils.getStatusColor(status_id);
    },
    getStatusColorName(status_id) {
      return report_utils.getStatusColorName(status_id);
    },
    getTagColorName(tag) {
      return tag_utils.getTagColorName(tag.slug);
    },
    getScore(score) {
      return this.$t(report_utils.getScoreTitle(score));
    },
    getScoreColor(score) {
      return report_utils.getScoreColor(score);
    },
    getScoreColorName(score) {
      return report_utils.getScoreColorName(score);
    },
    userFormatter() {
      if (!this.report.is_anonymous && this.report.user) {
        return this.report.user.firstname + " " + this.report.user.lastname;
      }
      return this.$t("anonymous");
    },
    isReporterExecutive() {
      return report_utils.isReporterExecutive(
        this.report?.user,
        this.report.is_anonymous,
      );
    },
    can(name) {
      return permissions_utils.can(name);
    },
  },
};
</script>

<style lang="scss">
.report-cell {
  border-radius: 12px;
  border-left-width: 0.75rem;
  border-left-style: solid;
  border-color: rgba(var(--color), 1);
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  &:hover {
    transform: scale(1.005);
    cursor: pointer;
  }
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }
  .report-row {
    margin-right: 0px;
    margin-left: 0px;
    .tags-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px 0px;
    }
  }
  .right-text {
    @media (max-width: 767px) {
      text-align: right;
    }
  }
  .tag-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-info {
      margin-left: 5px;
    }
  }
  .report-container {
    display: flex;
    flex-direction: row;

    .left-checkbox {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }
    .company-name {
      font-family: "Campton-Medium";
    }
    .username-text {
      font-size: 16px;
      display: flex;
      align-items: center;
      height: 30px;
      @media (min-width: 767px) {
        margin-bottom: 8px;
      }
      i {
        display: inline;
        margin-bottom: 10px;
        margin-left: 5px;
        color: $ui-light_blue;
      }
    }

    .assignees {
      display: block !important;
      ul {
        margin-top: 5px;
        padding-inline-start: 10px;
      }
    }

    .assignees-small {
      vertical-align: middle !important;
      ul {
        padding-inline-start: 10px;
      }
    }

    .report-title-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 8px;

      .report-title {
        text-align: left;
        color: $ui-text;
        font-family: "Campton-Book";
        font-size: 20px;
        display: inline-block;
      }
      .report-icon {
        height: 28px;
        .icon-info {
          margin-left: 2px;
        }
      }
    }
  }
}
</style>
