<template>
  <custom-modal title="organization" v-if="showCompanies" @close="closeModal">
    <div class="assign-button">
      <p v-if="Object.keys(this.current_company).length === 0">
        {{ $t("report_not_linked") }}
      </p>
      <p v-else>
        {{ $t("report_linked_to") }}
        <strong>{{ this.current_company.name }}</strong>
      </p>
      <p class="subtitle p-0 m-0">{{ $t("reassign_organization") }}</p>
      <div class="form-control">
        <input
          v-if="Object.keys(this.selected_company).length === 0"
          class="form-control-input"
          type="search"
          :placeholder="$t('search_organization')"
          v-model="searchText"
          :aria-label="$t('search_organization')"
        />
        <div v-else class="selected-company-container">
          <report-company
            :company="this.selected_company"
            v-on:action="removeCompany(c)"
            type="remove"
          />
        </div>
      </div>
      <div>
        <div
          class="search-container mt-2"
          v-if="this.companiesToShow.length > 0 && this.searchText !== ''"
        >
          <h6 class="subtitle px-2 pt-2 m-0">{{ $t("search") + ":" }}</h6>
          <ul class="scrollable-list p-2">
            <report-company
              class="pb-2"
              v-for="(c, index) in companiesToShow"
              v-bind:key="index"
              :company="c"
              v-on:action="addCompany(c)"
              type="add"
            />
          </ul>
        </div>
        <div v-else-if="searchText && this.companiesToShow.length === 0">
          <p style="text-align: center; margin-top: 10px">
            {{ $t("no_company_found") }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row button-container">
        <ui-button
          v-if="isUpdated"
          style="width: 50%; margin-top: 15px"
          full
          @click="saveCompany"
        >
          {{ $t("save") }}
        </ui-button>
        <ui-button
          v-if="isUpdated"
          context="red"
          style="width: 50%; margin-top: 15px"
          full
          outline
          @click="closeModal"
        >
          {{ $t("cancel") }}
        </ui-button>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from "@/components/modal/CustomModal.vue";
import ReportCompany from "./ReportCompany";
import UiButton from "@/components/ui-modules/Buttons/Button";

import companyApi from "@/api/companyApi";

export default {
  name: "AssignButton",
  components: {
    CustomModal,
    UiButton,
    ReportCompany,
  },
  props: {
    company: {
      type: Object,
      default: {},
    },
    showCompanies: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchText: "",
      isUpdated: false,
      isVisible: false,
      current_company: {},
      selected_company: {},
      companies: [],
      companies_to_show: [],
    };
  },
  created() {
    this.current_company = this.company || {};
    this.getCompanies();
  },
  computed: {
    companiesToShow() {
      return this.companies.filter((c) => {
        return (
          c.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          c.id == this.searchText
        );
      });
    },
  },
  methods: {
    getCompanies() {
      companyApi
        .getCompanies()
        .then((response) => {
          this.companies = response.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    addCompany(item) {
      this.isUpdated = true;
      this.selected_company = item;
      this.searchText = "";
    },
    removeCompany() {
      this.isUpdated = false;
      this.selected_company = {};
      this.searchText = "";
    },
    saveCompany() {
      this.isUpdated = false;
      this.$emit("save", this.selected_company);
      this.current_company = this.company || {};
      this.selected_company = {};
      this.searchText = "";
    },
    closeModal() {
      this.isUpdated = false;
      this.current_company = this.company || {};
      this.selected_company = {};
      this.$emit("close");
      this.searchText = "";
    },
  },
};
</script>

<style lang="scss">
.assign-button {
  font-family: "Campton-Book";
  .search-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .form-control {
    padding: 0px;
    position: relative;
    .form-control-input {
      border-radius: 0.25rem;
      border-width: 0px;
      border-color: transparent;
      width: 100%;
      padding: 6px 12px 6px 12px;
    }
    .selected-company-container {
      padding: 6px 12px 6px 12px;
    }
  }

  .title {
    color: $ui-text;
    font-size: 16px;
    font-family: "Campton-Bold";
    border-bottom: 1px solid $ui-light_grey;
  }
  .subtitle {
    text-transform: uppercase;
    color: $ui-subtitle;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Campton-Medium";
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }

  .scrollable-list {
    max-height: calc(4 * 60px + 20px);
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
</style>
