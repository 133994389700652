import store from "../store/index";
import api from "./api";
import url_utils from "@/utils/url_utils";

const base_url = (custom) => {
  return custom ? custom : url_utils.getEnvVariable("api_url");
};

const request = (url) => {
  return api.request(base_url() + url);
};

const request_logged = (url, parameters) => {
  return api.request_logged(base_url() + url, parameters);
};

const request_logged_custom = (custom_base_url, url, parameters) => {
  return api.request_logged(base_url(custom_base_url) + url, parameters);
};

const request_logged_custom_blob = (custom_base_url, url, parameters) => {
  return api.request_logged_blob(base_url(custom_base_url) + url, parameters);
};

const request_logged_delete = (url, parameters) => {
  return api.request_logged_delete(base_url() + url, parameters);
};

const request_logged_put = (url, parameters) => {
  return api.request_logged_put(base_url() + url, parameters);
};

const request_logged_put_json = (url, parameters) => {
  return api.request_logged_put_json(base_url() + url, parameters);
};

const request_logged_patch = (url, parameters) => {
  return api.request_logged_patch(base_url() + url, parameters);
};

const request_post = (url, parameters) => {
  return api.request_post(base_url() + url, parameters);
};

const request_post_custom = (custom_base_url, url, parameters) => {
  return api.request_logged_post(base_url(custom_base_url) + url, parameters);
};

const request_logged_post = (url, parameters) => {
  return api.request_logged_post(base_url() + url, parameters);
};

const request_logged_post_multipart = (url, parameters) => {
  return api.request_logged_post_multipart(base_url() + url, parameters);
};

const request_logged_post_multipart_progress = (
  url,
  parameters,
  onUploadProgress,
) => {
  return api.request_logged_post_multipart_progress(
    base_url() + url,
    parameters,
    onUploadProgress,
  );
};

export default {
  request,
  request_logged,
  request_logged_custom,
  request_logged_custom_blob,
  request_logged_delete,
  request_logged_put,
  request_logged_put_json,
  request_logged_patch,
  request_post,
  request_post_custom,
  request_logged_post,
  request_logged_post_multipart,
  request_logged_post_multipart_progress,
};
