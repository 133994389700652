<template>
  <li class="sidebar-cell" v-if="canShowSidebarCell(title)">
    <router-link
      :style="{ '--color': this.color }"
      :to="to"
      :target="target"
      class="sidebar-cell_router-link"
      @mouseover.native="updateHoverState(true)"
      @mouseleave.native="updateHoverState(false)"
    >
      <div :class="isSpecial ? 'icon-button' : 'icon-container'">
        <i :class="['fa menu_icon', icon]" aria-hidden="true" />
      </div>
      <p class="menu_title">{{ $t(title) }}</p>
      <!-- <span slot="title"> -->
      <!-- <i
          :class="['fa menu__icon', item.icon]"
          aria-hidden="true"/> {{ item.name }} -->
      <!-- </span> -->
      <slot name="title" />
    </router-link>
    <div v-if="this.addSeparator" class="separator" />
  </li>
</template>

<script>
export default {
  name: "SidebarCell",
  props: {
    to: {
      type: Object,
      required: true,
    },
    target: {
      type: String,
      default: "_self",
    },
    color: {
      type: String,
      default: "#c1473a",
    },
    addSeparator: {
      type: Boolean,
      default: false,
    },
    isSpecial: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "title",
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      hoverState: false,
    };
  },
  watch: {
    $route(route) {
      window.bus.$emit("menu/closeMobileMenu");
      this.$nextTick(() => {
        const isActive =
          this.$children[0] &&
          this.$children[0].$el.classList.contains("router-link-active");
        if (!isActive) {
          return;
        }
      });
    },
  },
  methods: {
    updateHoverState(isHover) {
      this.hoverState = isHover;
    },
    canShowSidebarCell(title) {
      if (title === "pages.settings.entities") {
        if (
          !this.$store.state.subcompanies ||
          this.$store.state.subcompanies.length === 0
        ) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-cell_router-link {
  position: relative;
  padding: 5px 5px 5px 5px;
  margin: 5px 20px 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: $ui-subtitle;
  transition:
    color 0.5s,
    background-color 1s,
    font-weight 0.1s ease;
  font-family: "Campton-Book";
}

.sidebar-cell_router-link:hover {
  font-family: "Campton-Medium";
}

.sidebar-cell_router-link.router-link-active,
.sidebar-cell_router-link.router-link-exact-active {
  color: $ui-text;
  text-decoration: none;
  background-color: $ui-lighter_grey;
  font-family: "Campton-Medium";
  border-radius: 5px;
  .icon-container {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.separator {
  border-bottom: 0px solid $ui-light_grey;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: 30px 0px 30px 25px;
}

.sidebar-cell_router-link:hover {
  opacity: 0.85;
}

.menu_icon {
  // font-size: 16px;
  // // width: 16px;
  // text-align: center;
  // margin-right: 10px;
  // color: white;
  font-size: 12px;
  text-align: center;
  display: inline;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
}

.menu_title {
  font-size: 15px;
  letter-spacing: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.icon-button {
  margin-right: 16px;
}

.icon-container {
  // background-color: var(--color);
  // border-radius: 6px;
  // padding: 10px;
  font-family: "Avenir";
  display: inline-block;
  font-size: 40px;
  line-height: 9px;
  background-color: var(--color);
  color: white;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 5px;
  vertical-align: bottom;
  margin-right: 16px;
}
</style>
