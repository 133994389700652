<template>
  <div class="dashboard-cell location-cell">
    <div class="location-container">
      <template v-if="!isLoading">
        <div class="left-content">
          <div class="location-circle">
            <img :src="icon" />
          </div>
        </div>
        <a style="width: 100%; align-self: center">
          <div class="row location-row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 my-auto">
              <p class="location-title">{{ locationTitle }}</p>
              <p class="location-subtitle">{{ locationSubtitle }}</p>
            </div>
          </div>
        </a>
      </template>
      <template v-else>
        <div style="width: 100%; align-self: center; text-align: center">
          <i class="fas fa-circle-notch fa-spin spinner"></i>
        </div>
      </template>
      <div class="right-content">
        <router-link
          :to="{
            path: '/reports?location_id=' + this.location.id,
          }"
        >
          <ui-tag context="green" subtle circle hoverShadow small>{{
            getNumberReport(location.nb_reports)
          }}</ui-tag>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import google_place_type from "@/utils/google_place_type";
import dashboardApi from "@/api/dashboardApi";

export default {
  name: "locationCell",
  components: {
    UiTag,
  },
  data() {
    return {
      isLoading: true,
      icon: "",
      locationTitle: "",
      locationSubtitle: "",
    };
  },
  props: ["location"],
  created() {
    if (this.location.question_type === 3) {
      this.placeIdToLongLat(this.location.location);
    } else {
      this.setLocationTag();
    }
  },
  methods: {
    placeIdToLongLat(place_id) {
      dashboardApi
        .getPlace(place_id)
        .then((resolve) => {
          this.icon = resolve.icon;
          this.locationTitle = resolve.title;
          this.locationSubtitle = resolve.subtitle;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.status === "UNKNOWN_ERROR") {
            this.icon = google_place_type.getMapIcon(["establishment"]);
            this.locationTitle = place_id;
            this.isLoading = false;
          } else if (err.status === "OVER_QUERY_LIMIT") {
            setTimeout(() => {
              this.placeIdToLongLat(place_id);
            }, 5000);
          } else {
            this.icon = google_place_type.getMapIcon(["establishment"]);
            this.locationTitle = err.error;
            this.isLoading = false;
          }
        });
    },
    setLocationTag() {
      this.icon = this.getMapIcon(["geocode"]);
      this.locationTitle = this.location.location;
      this.isLoading = false;
    },
    getNumberReport(nb_report) {
      return (
        nb_report +
        " " +
        (nb_report !== 1 ? this.$t("reports_noun") : this.$t("report_noun"))
      );
    },
    getMapIcon(types) {
      return google_place_type.getMapIcon(types);
    },
    sleep(milliSeconds) {
      var startTime = new Date().getTime();
      while (new Date().getTime() < startTime + milliSeconds);
    },
  },
};
</script>

<style lang="scss">
.location-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .location-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .location-container {
    display: flex;
    flex-direction: row;

    .left-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .right-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .location-title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium";
      font-size: 16px;
    }
    .location-subtitle {
      text-align: left;
      color: $ui-dark_grey;
      font-family: "Campton-Book";
      font-size: 14px;
    }

    .location-circle {
      width: 50px;
      height: 50px;
      background-color: $ui-lighter_grey;
      text-align: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;

      img {
        position: relative;
        top: 25%;
        width: 25px;
        line-height: 25px;
        font-family: "Campton-Medium";
      }
    }

    .spinner {
      font-size: 30px;
      margin: 10px 0px;
      color: $ui-lighter_grey;
    }
  }
}
</style>
