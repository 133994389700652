<template>
  <div class="dashboard-cell behavior-cell">
    <div class="behavior-container">
      <!-- <div class="left-content">
        <avatar-initial :name="behavior.fullname" color="12, 32, 61" />
      </div> -->
      <a style="width: 100%; align-self: center">
        <div class="row behavior-row">
          <div class="col-lg-8 col-md-8 col-sm-8 col-7 my-auto">
            <p class="behavior-title">
              {{ behavior.behavior }}
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-5 my-auto">
            <el-progress
              :percentage="setPercentage(behavior.nb_reports)"
              :show-text="false"
              color="#1f5688"
            />
          </div>
        </div>
      </a>
      <div class="right-content">
        <router-link :to="{ path: '/reports?behavior_id=' + behavior.id }">
          <ui-tag context="blue" subtle circle hoverShadow small>{{
            getNumberReport(behavior.nb_reports)
          }}</ui-tag>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import AvatarInitial from "@/components/AvatarInitial";

export default {
  name: "BehaviorCell",
  components: {
    UiTag,
    AvatarInitial,
  },
  props: ["behavior", "total_behaviors", "maxValue"],
  methods: {
    getNumberReport(nb_report) {
      return (
        nb_report +
        " " +
        (nb_report !== 1 ? this.$t("reports_noun") : this.$t("report_noun"))
      );
    },
    setPercentage(nb_reports) {
      return Math.min(100, nb_reports * (100 / this.maxValue));
    },
  },
};
</script>

<style lang="scss">
.behavior-cell {
  transition: all 0.1s ease-in-out;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  a {
    color: $ui-text;
    text-decoration: none;
  }
  p {
    margin-bottom: 0px;
  }

  .behavior-row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .behavior-container {
    display: flex;
    flex-direction: row;

    .left-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }
    .right-content {
      padding-left: 20px;
      padding-right: 20px;
      align-self: center;
    }

    .behavior-title {
      text-align: left;
      color: $ui-text;
      font-family: "Campton-Medium";
    }
  }
}
</style>
