var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('sidebar-container',[_c('template',{slot:"menu"},[_c('div',{staticClass:"fixed-header"},[_c('router-link',{staticClass:"menu__logo",attrs:{"to":_vm.defaultRoute}},[_c('img',{staticClass:"notme-logo",attrs:{"src":require("@/assets/logo-notme.png"),"alt":"icon"}})])],1),_c('div',{class:[
          'menu',
          _vm.currentRoutes === _vm.settingsRoutes || _vm.currentRoutes === _vm.profileRoutes
            ? 'menu-setting-padding'
            : '',
        ]},[_c('ul',_vm._l((_vm.currentRoutes),function(item,index){return _c('sidebar-cell',{key:index,attrs:{"to":{ name: item.name },"color":item.color || '',"add-separator":item.addSeparator,"title":item.title,"icon":item.icon}})}),1),(_vm.hasMasterSelector())?_c('master-selector'):_vm._e()],1),(
          (_vm.currentRoutes === _vm.settingsRoutes ||
            _vm.currentRoutes === _vm.profileRoutes) &&
          _vm.userRole !== 'editor'
        )?_c('div',{staticClass:"back-footer"},[_c('ul',{staticClass:"menu"},[_c('sidebar-cell',{staticStyle:{"margin-top":"0px"},attrs:{"to":{
              name:
                _vm.defaultRouteName === 'index' ? 'overview' : _vm.defaultRouteName,
            },"color":"#5f9e83","title":"pages.back_to_dashboard","icon":"fa-arrow-left","isSpecial":true}})],1)]):_vm._e(),_c('div',{staticClass:"fixed-footer"},[_c('user-dropdown')],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }