<template>
  <authentication-layout
    :title="$t('authentication_section.log_in')"
    :subtitle="$t('authentication_section.log_in_description')"
  >
    <div class="login">
      <ui-input
        :model.sync="user_login.email"
        type="email"
        :label="$t('user.email')"
      />
      <ui-input
        :model.sync="user_login.password"
        type="password"
        :label="$t('user.password')"
        :rightLabel="$t('authentication_section.forgot_password')"
        :rightAction="forgotPassword"
        :onEnterPressed="canLoginEnter"
      />
      <ui-button
        context="blue"
        full
        :disabled="!canLogin"
        :loading="isLoading"
        @click="login"
      >
        {{
          isLoading
            ? $t("authentication_section.logging_in")
            : $t("authentication_section.login")
        }}
      </ui-button>
      <p>
        {{ $t("authentication_section.dont_have_an_account_yet") }}
        <a :href="this.contact_us_link">{{
          $t("authentication_section.request_a_demo")
        }}</a>
      </p>
    </div>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from "@/layouts/AuthenticationLayout";
import UiInput from "@/components/ui-modules/Input";
import UiButton from "@/components/ui-modules/Buttons/Button";
import Selector from "@/components/ui-modules/Selector";
import userApi from "@/api/userApi";
import challengeApi from "@/api/challengeApi";
import api from "@/api/api";
import firebase_config from "@/utils/firebase-config";
import permission_utils from "@/utils/permissions_utils";
import { initializeApp } from "firebase/app";
import url_utils from "@/utils/url_utils";

export default {
  name: "Login",
  components: {
    UiInput,
    UiButton,
    Selector,
    AuthenticationLayout,
  },
  data() {
    return {
      user_login: {
        email: "",
        password: "",
      },
      isLoading: false,
      mfa_counter: 60,
      can_resend: false,
      interval: null,
      counter_started: false,
      contact_us_link:
        url_utils.getWebsiteUrl(this.$store.state.language) + "/contact-us",
      is_remember_me: false,
      phone: "",
    };
  },
  computed: {
    canLogin() {
      return (
        this.user_login.email &&
        this.user_login.email !== "" &&
        url_utils.checkEmail(this.user_login.email) &&
        this.user_login.password !== "" &&
        this.user_login.password.length >= 8
      );
    },
  },
  methods: {
    loginAttempt() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("login", this.user_login)
          .then((response) => {
            this.$message({
              message: this.$t("authentication_section.welcome_name", {
                name: response.user.firstname,
              }),
              type: "success",
            });
            this.$router.replace({ path: permission_utils.getDefaultRoute() });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.loginAttempt()
          .then((response) => {
            this.isLoading = false;
            this.initializeFirebase();
            /** E3 - Restore private key */
            if (this.$store.getters.isE2Eenabled()) {
              this.$store.dispatch("initializeDevice").then((_) => {
                this.$store.state.device.cleanup();
                this.$store.state.device
                  .hasLocalPrivateKey()
                  .then((hasLocalPrivateKey) => {
                    console.log(hasLocalPrivateKey);
                    if (!hasLocalPrivateKey) {
                      this.$store.state.device.restorePrivateKey(
                        this.user_login.password,
                      );
                    } else {
                      console.log(hasLocalPrivateKey);
                      console.log("true");
                    }
                  });
              });
            }
          })
          .catch((err) => {
            switch (err.type) {
              case "verify_email":
                this.isLoading = false;
                this.$confirm(err.response, this.$t("error"), {
                  confirmButtonText: this.$t("resend_confirmation"),
                  cancelButtonText: this.$t("cancel"),
                  type: "error",
                }).then(() => {
                  this.verifyMail();
                });
                break;
              case "2FA":
                this.goToTwoFactor();
                break;
              case "phone_not_verified":
                this.goToVerificationPhone();
                break;
              default:
                this.isLoading = false;
                if (err) {
                  this.$message.error(err.response);
                }
                break;
            }
          });
      }
    },
    goToTwoFactor() {
      this.$router.push({
        name: "TwoFactor",
        params: { user_login: this.user_login },
      });
    },
    goToVerificationPhone() {
      this.$router.push({
        name: "VerificationPhone",
        params: { password: this.user_login.password },
      });
    },
    forgotPassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
    canLoginEnter() {
      if (this.canLogin) {
        this.login();
      }
    },
    verifyMail() {
      userApi
        .verifyMail(this.user_login.email)
        .then((response) => {
          this.$message({
            message: this.$t("confirmation_email_sent"),
            type: "success",
          });
        })
        .catch((err) => {
          this.$message.error(api.getErrorMessage(err));
        });
    },
    initializeFirebase() {
      let config = firebase_config;
      initializeApp(config);
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

.login {
  .login-header {
    text-align: left;
    margin-bottom: 30px;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
