<template>
  <report-card
    title="resolution"
    :button_icon="can('update assigned report') ? 'fas fa-pencil-alt' : ''"
    v-on:action="$emit('open-resolution')"
  >
    <div v-if="!resolution" style="text-align: center">
      <h5 class="title">{{ $t("no_resolution") }}</h5>
      <p class="subtitle">{{ $t("no_resolution_explanation") }}</p>
    </div>
    <template v-else>
      <div class="resolution-header">
        <div class="resolution-header_info" v-html="addedByText" />
      </div>
      <report-resolution-summary
        :isSidebar="true"
        :conclusionsData="resolution.conclusionsData"
        :conclusionDetailsData="resolution.conclusionDetailsData"
        :accusedOutcome="resolution.accusedOutcome"
        :accusedOutcomeDetails="resolution.accusedOutcomeDetails"
      />
    </template>
  </report-card>
</template>

<script>
import ReportCard from "./ReportCard";
import ReportResolutionSummary from "./ResolutionModal/ReportResolutionSummary";

import permissions_utils from "@/utils/permissions_utils";
import userApi from "@/api/userApi";
import moment from "moment";

export default {
  name: "ReportResolution",
  components: {
    ReportCard,
    ReportResolutionSummary,
  },
  props: {
    resolution: {
      type: Object,
    },
  },
  data() {
    return {
      updatedBy: "",
      formattedDate: "",
    };
  },
  async created() {
    this.fetchUserData();
  },
  computed: {
    addedByText() {
      if (this.updatedBy && this.formattedDate) {
        return this.$t("resolution_submitted_by", {
          user: this.updatedBy,
          date: this.formattedDate,
        });
      }
      return "";
    },
  },
  watch: {
    resolution: {
      handler: "fetchUserData",
      deep: true,
    },
  },
  methods: {
    can(name) {
      return permissions_utils.can(name);
    },
    async fetchUserData() {
      if (this.resolution && this.resolution.updated_by) {
        try {
          const userRes = await userApi.getUserById(this.resolution.updated_by);
          const user = userRes.data;
          this.updatedBy = user.firstname + " " + user.lastname;
          this.formattedDate = moment(this.resolution.updated_on).format(
            "MM/DD/YYYY",
          );
        } catch (_) {} // Do we want to toast anything here?
      }
    },
  },
};
</script>

<style lang="scss">
.resolution-header {
  margin-top: -15px;
  .resolution-header_info {
    margin-bottom: 10px;
    font-size: 14px;
    color: $ui-subtitle;
    font-family: "Campton-Medium";
    span {
      font-family: "Campton-Bold";
    }
  }
}
</style>
