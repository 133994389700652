const tags = [
  { slug: "confidential", color: "#441313", color_name: "dark_red" },
  { slug: "non-responsive", color: "#001b39", color_name: "dark_blue" },
  { slug: "privileged", color: "#1c78a5", color_name: "light_blue" },
  {
    slug: "privileged-and-confidential",
    color: "#906500",
    color_name: "dark_yellow",
  },
  { slug: "proprietary", color: "#5f9e83", color_name: "green" },
  { slug: "relevant", color: "#c1473a", color_name: "red" },
  { slug: "responsive", color: "#1f5688", color_name: "blue" },
  { slug: "trade-secret", color: "#c69c85", color_name: "light_red" },
  { slug: "work-product", color: "#3a6e58", color_name: "dark_green" },
];

const getTagColorName = (slug) => {
  return tags.find((element) => element.slug === slug).color_name;
};

const getTagColor = (slug) => {
  return tags.find((element) => element.slug === slug).color;
};

const getTag = (slug) => {
  return tags.find((element) => element.slug === slug);
};

export default { tags, getTagColorName, getTagColor, getTag };
