<template>
  <custom-modal title="assignment" v-if="showAssignees" @close="closeModal">
    <div class="assign-button">
      <ui-input
        :model.sync="searchText"
        type="search"
        :placeholder="$t('add_members')"
        :aria-label="$t('add_members')"
      />
      <div>
        <div v-if="this.membersToShow.length > 0">
          <h6 class="subtitle">{{ $t("members") }}</h6>
          <ul class="scrollable-list">
            <user-cell
              v-for="(user, index) in membersToShow"
              v-bind:key="index"
              :user="user"
              v-on:action="addAssignee(user)"
              type="add"
            />
          </ul>
        </div>
        <div v-else-if="searchText && this.membersToShow.length === 0">
          <p style="text-align: center; margin-top: 10px">
            {{ $t("no_user_found") }}
          </p>
        </div>
      </div>
      <div v-if="current_assignees.length > 0">
        <h6 class="subtitle">{{ $t("assigned_to") }}</h6>
        <ul class="scrollable-list">
          <user-cell
            v-for="(user, index) in current_assignees"
            v-bind:key="`${index}-${user.id}`"
            :user="user"
            v-on:action="removeAssignee(user)"
            type="remove"
          />
        </ul>
      </div>
      <ui-button
        v-if="isUpdated"
        style="margin-top: 15px"
        full
        @click="saveAssignees"
        >{{ $t("save") }}
      </ui-button>
    </div>
  </custom-modal>
</template>

<script>
import UserCell from "@/components/UserCell";
import CustomModal from "@/components/modal/CustomModal.vue";
import UiInput from "@/components/ui-modules/Input";
import UiButton from "@/components/ui-modules/Buttons/Button";

import companyApi from "@/api/companyApi";

export default {
  name: "AssignButton",
  components: {
    UserCell,
    CustomModal,
    UiInput,
    UiButton,
  },
  props: {
    assignees: {
      type: Array,
      default: [],
    },
    showAssignees: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchText: "",
      isUpdated: false,
      company_members: [],
      isVisible: false,
      current_assignees: [],
    };
  },
  created() {
    this.current_assignees = [...this.assignees];
    this.getCompanyUsers();
  },
  watch: {
    showAssignees(newVal) {
      if (newVal) {
        this.current_assignees = [...this.assignees];
      }
    },
    current_assignees(newVal) {
      if (newVal.length === this.assignees.length && this.isUpdated) {
        const newAssignees = this.assignees.filter(
          (item) => !newVal.some((newItem) => newItem.id === item.id),
        );
        if (newAssignees.length < 1) {
          this.isUpdated = false;
        }
      }
    },
  },
  computed: {
    membersToShow() {
      var members = this.company_members.filter((element) => {
        return !this.current_assignees.find(function (elementB) {
          return element.id === elementB.id;
        });
      });

      return members.filter((member) => {
        return (
          member.firstname
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          member.lastname
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          (
            member.firstname.toLowerCase() + member.lastname.toLowerCase()
          ).includes(this.searchText.replace(/\s/g, "").toLowerCase())
        );
      });
    },
  },
  methods: {
    getCompanyUsers() {
      companyApi
        .getCompanyDashboardUsers()
        .then((response) => {
          this.company_members = response.data.users;
        })
        .catch((_) => {});
    },
    removeAssignee(item) {
      const index = this.current_assignees.indexOf(item);
      if (index > -1) {
        this.isUpdated = true;
        this.current_assignees.splice(index, 1);
      }
    },
    addAssignee(item) {
      this.isUpdated = true;
      this.current_assignees.push(item);
    },
    saveAssignees() {
      this.isUpdated = false;
      this.$emit("save", this.current_assignees);
    },
    closeModal() {
      this.isUpdated = false;
      this.current_assignees = [...this.assignees];
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.assign-button {
  font-family: "Campton-Book";
  .title {
    color: $ui-text;
    font-size: 16px;
    font-family: "Campton-Bold";
    border-bottom: 1px solid $ui-light_grey;
  }
  .subtitle {
    text-transform: uppercase;
    color: $ui-subtitle;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: "Campton-Medium";
    font-size: 12px;
    letter-spacing: 0.3px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }

  .scrollable-list {
    max-height: calc(4 * 60px + 20px);
    padding: 0;
    margin: 0;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .users-to-add {
    li:first-child {
      padding-top: 0px;
    }
  }
}
</style>
