<template>
  <li class="search-query-cell">
    <p class="search-query-cell-title">{{ $t(category.title) }}</p>
    <div class="search-query-cell-value-container" v-if="value">
      <status-query-value v-if="category.type === 'status'" :value="value" />
      <score-query-value v-else-if="category.type === 'score'" :value="value" />
      <anonymous-query-value
        v-else-if="category.type === 'anonymous'"
        :value="value"
      />
      <unassigned-query-value
        v-else-if="category.type === 'unassigned'"
        :value="value"
      />
      <assignee-query-value
        v-else-if="category.type === 'assignee'"
        :value="value"
      />
      <date-query-value v-else-if="category.type === 'date'" :value="value" />
      <tag-query-value v-else-if="category.type === 'tag'" :value="value" />
      <div v-else>
        <p>err</p>
      </div>
      <i class="fas fa-times remove-button" @click="$emit('removeQuery')" />
    </div>
  </li>
</template>

<script>
import ReportValue from "./TypeQueryValue/ReportValue";
import TextValue from "./TypeQueryValue/TextValue";
import UserValue from "./TypeQueryValue/UserValue";
import LocationValue from "./TypeQueryValue/LocationValue";

import StatusQueryValue from "./SearchQueryValue/StatusQueryValue";
import ScoreQueryValue from "./SearchQueryValue/ScoreQueryValue";
import AnonymousQueryValue from "./SearchQueryValue/AnonymousQueryValue";
import UnassignedQueryValue from "./SearchQueryValue/UnassignedQueryValue";
import AssigneeQueryValue from "./SearchQueryValue/AssigneeQueryValue";
import DateQueryValue from "./SearchQueryValue/DateQueryValue";
import TagQueryValue from "./SearchQueryValue/TagQueryValue";

export default {
  name: "SearchQueryCell",
  components: {
    ReportValue,
    TextValue,
    UserValue,
    LocationValue,
    StatusQueryValue,
    ScoreQueryValue,
    AnonymousQueryValue,
    UnassignedQueryValue,
    AssigneeQueryValue,
    DateQueryValue,
    TagQueryValue,
  },
  props: {
    category: {
      //report, text, user, location
      type: Object,
    },
    index: {
      type: Number,
    },
    value: {
      type: Object,
    },
  },
  methods: {
    removeQuery() {},
  },
};
</script>

<style lang="scss">
.search-query-cell {
  background-color: $ui-white;
  border-radius: 4px;
  font-family: "Campton-Medium";
  font-size: 14px;
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 4px;
  justify-content: center;
  align-items: center;
  .search-query-cell-title {
    padding: 0px 6px;
    margin: 0px;
    text-transform: capitalize;
  }
  .search-query-cell-value-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    background-color: $ui-light_grey;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 10px;
    .remove-button {
      cursor: pointer;
      color: $ui-grey;
      transition: 0.25s ease-in-out;
      &:hover {
        color: $ui-text;
      }
    }
  }
}
</style>
