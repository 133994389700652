<template>
  <div class="type-query-value">
    <avatar-name
      :name="user.firstname + ' ' + user.lastname"
      :id="parseInt(user.id)"
      :size="22"
    />
    <p class="type-query-value-title">
      {{ user.firstname + " " + user.lastname }}
    </p>
  </div>
</template>

<script>
import AvatarName from "@/components/ui-modules/Avatar/Base";

export default {
  name: "UserValue",
  components: {
    AvatarName,
  },
  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import "./styles.scss";
</style>
