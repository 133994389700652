<template>
  <div v-if="type === 'notes'">
    <timeline-note :user="updated_by" :item="item" />
  </div>
  <div v-else-if="type === 'info'">
    <timeline-info :user="updated_by" :item="item" :users="users" />
  </div>
  <div v-else-if="type === 'docs'">
    <timeline-doc
      :user="updated_by"
      :item="item"
      :users="users"
      :report_status_id="report.status_id"
    />
  </div>
  <div v-else-if="type === 'assignees'">
    <timeline-assignee :user="updated_by" :item="item" :users="users" />
  </div>
  <div v-else-if="type === 'tags'">
    <timeline-tag :user="updated_by" :item="item" />
  </div>
  <div v-else-if="type === 'resolution'">
    <timeline-resolution :user="updated_by" :item="item" />
  </div>
</template>

<script>
import TimelineNote from "@/components/TimelineNote";
import TimelineAssignee from "@/components/TimelineAssignee";
import TimelineDoc from "@/components/TimelineDoc.vue";
import TimelineInfo from "@/components/TimelineInfo.vue";
import TimelineTag from "@/components/TimelineTag.vue";
import TimelineResolution from "@/components/TimelineResolution.vue";

export default {
  name: "TimelineEvent",
  components: {
    TimelineNote,
    TimelineAssignee,
    TimelineDoc,
    TimelineInfo,
    TimelineTag,
    TimelineResolution,
  },
  props: {
    item: {
      type: Object,
    },
    users: {
      type: Object,
    },
    report: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    updated_by() {
      return this.users[this.item.updated_by];
    },
    type() {
      return this.item.type;
    },
    files() {
      return this.item.files;
    },
  },
};
</script>

<style lang="scss">
.timeline-event {
  min-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left-width: 2.5px;
  border-left-color: $ui-lighter_grey;
  border-left-style: solid;
  display: flex;
  flex-direction: row;
  .timeline-icon-wrapper {
    background-color: $ui-white;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 50px;
    margin-left: -21.25px;
    .timeline-icon {
      background-color: $ui-background;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 0px;
      margin-bottom: 0px;
      height: 40px;
      width: 40px;
      border-radius: 5px;
      i {
        color: $ui-blue;
        font-size: 14px;
      }
    }
  }
  .timeline-content-container {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    .content {
      display: flex;
      font-family: "Campton-Medium";
      font-size: 16px;
      .title-container {
        display: inline-block;
        text-align: left;
        .inline-header {
          display: inline;
        }
      }
      .translation {
        color: $ui-subtitle;
        span {
          &:not(.notme-tag) {
            color: $ui-blue;
          }
        }
      }
    }
    .date {
      display: flex;
      font-size: 14px;
      color: $ui-dark_grey;
      font-family: "Campton-Book";
    }
  }
}
</style>
