import { render, staticRenderFns } from "./TopWidget.vue?vue&type=template&id=24ac2de8&scoped=true"
import script from "./TopWidget.vue?vue&type=script&lang=js"
export * from "./TopWidget.vue?vue&type=script&lang=js"
import style0 from "./TopWidget.vue?vue&type=style&index=0&id=24ac2de8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ac2de8",
  null
  
)

export default component.exports