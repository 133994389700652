<template>
  <div class="type-query-value">
    <p class="type-query-value-title">{{ value.value }} - TODO Location</p>
  </div>
</template>

<script>
export default {
  name: "LocationValue",
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import "./styles.scss";
</style>
