<template>
  <report-card
    title="document"
    :count="report_files.length"
    :button_icon="can('update assigned report') ? 'fas fa-plus' : ''"
    description="documents_description"
    v-on:action="showCustomModal = true"
  >
    <input
      type="file"
      id="logoUpload"
      ref="file"
      style="display: none"
      multiple
      @click="onInputClicked"
      @change="onFilePicked"
    />

    <custom-modal
      v-if="showCustomModal"
      :title="$t('document')"
      :max_width="500"
      @close="closeDocumentModal"
    >
      <template v-slot:default>
        <div class="report-document-modal-content">
          <!-- Clickable Div for File Selection -->
          <div @click="openFileBrowser" class="file-selection">
            <div class="icon-container">
              <i class="fas fa-file" />
            </div>
            <span>{{ $t("select_file_to_upload") }}</span>
          </div>

          <!-- Separator -->
          <div class="separator">
            <span>{{ $t("or") }}</span>
          </div>

          <!-- URL Input -->
          <ui-input
            :label="$t('document_link_google_drive')"
            :model.sync="urlToAdd"
            type="url"
            class="url-input"
            placeholder="e.g. https://drive.google.com/..."
          />
          <ui-input
            :label="$t('title_optional')"
            :model.sync="urlTitle"
            type="text"
            class="url-input"
          />
          <!-- Buttons container -->
          <div class="report-document-modal-footer">
            <ui-button context="blue" outline @click="closeDocumentModal">
              {{ $t("cancel") }}
            </ui-button>
            <ui-button
              context="blue"
              :disabled="!isValidGoogleDriveURL"
              :loading="isUrlUploading"
              @click="uploadGoogleDriveDocument"
            >
              {{ $t("submit") }}
            </ui-button>
          </div>
        </div>
      </template>
    </custom-modal>

    <div
      v-if="report_files.length === 0 && filesToUpload.length === 0"
      style="text-align: center"
    >
      <h5 class="title">{{ $t("no_documents") }}</h5>
      <p class="subtitle">{{ $t("add_documents_explanation") }}</p>
    </div>
    <div class="files-to-upload-container" v-if="filesToUpload.length > 0">
      <div v-if="upload_status === 'idle'">
        <report-file-upload
          v-for="(file, index) in filesToUpload"
          :key="index"
          :report_file="file"
          v-on:remove-file="removeFileUpload(index)"
        />
        <ui-button
          full
          context="green"
          class="upload-button"
          @click="uploadDocument"
        >
          {{ $t("upload") }}
        </ui-button>
      </div>
      <div v-else class="files-to-upload--uploading">
        <h5 class="files-to-upload--uploading-text">
          {{ upload_status === "uploaded" ? $t("uploaded") : $t("uploading") }}
        </h5>
        <el-progress
          v-if="upload_status === 'uploading'"
          type="circle"
          :width="75"
          color="#5f9e83"
          :percentage="upload_progress"
        >
        </el-progress>
        <div v-else style="font-family: &quot;Avenir&quot;">
          <i
            class="far fa-check-circle"
            style="font-size: 45px; color: #5f9e83; padding: 15px"
          ></i>
        </div>
      </div>
    </div>

    <report-file
      v-for="(file, index) in report_files"
      :key="index"
      :report_file="file"
      :report_status_id="report_status_id"
      v-on:preview-file="previewFile(file)"
      :deleteDocument="deleteDocument"
    />
    <div style="height: 65px" />

    <file-preview-modal
      v-if="showFile"
      :selectedFile="selectedFile"
      :report_status_id="report_status_id"
      :closeModal="closeModal"
      :downloadAction="downloadAction"
      :deleteDocument="deleteDocument"
    />
  </report-card>
</template>

<script>
import moment from "moment";
import CustomModal from "@/components/modal/CustomModal";
import ReportCard from "./ReportCard";
import ReportFile from "./ReportFile";
import ReportFileUpload from "./ReportFileUpload";
import UiButton from "@/components/ui-modules/Buttons/Button";
import UiInput from "@/components/ui-modules/Input";
import url_utils from "@/utils/url_utils";
import report_utils from "@/utils/report_utils";
import permissions_utils from "@/utils/permissions_utils";
import FilePreviewModal from "@/components/FilePreviewModal.vue";

export default {
  name: "ReportDocument",
  components: {
    ReportCard,
    ReportFile,
    ReportFileUpload,
    UiButton,
    UiInput,
    FilePreviewModal,
    CustomModal,
  },
  props: {
    report_files: {
      type: Array,
    },
    report_status_id: {
      type: Number,
    },
    upload_progress: {
      type: Number,
    },
    isUrlUploading: {
      type: Boolean,
    },
  },
  data() {
    return {
      showFile: false,
      selectedFile: null,
      filesToUpload: [],
      upload_status: "idle", //"uploading", "uploaded"
      isUploaded: false,
      showCustomModal: false,
      urlToAdd: "",
      urlTitle: "",
    };
  },
  watch: {
    upload_progress(oldV, newV) {
      if (this.upload_progress === 100) {
        this.upload_status = "uploaded";
        let i = 0;
        var timer = setInterval(() => {
          if (i === 2) {
            clearInterval(timer);
            i = 0;
            this.resetUpload();
          } else i++;
        }, 1000);
      }
    },
  },
  computed: {
    isValidGoogleDriveURL() {
      return url_utils.isValidGoogleDriveURL(this.urlToAdd);
    },
  },
  methods: {
    openFileBrowser() {
      this.$refs.file.click();
    },
    previewFile(file) {
      let file_type = report_utils.getFileTypeFromURL(file.file);
      if (report_utils.isImage(file_type)) {
        this.selectedFile = file;
        this._updateModalVisibility();
      } else {
        this.downloadAction(file.file);
      }
    },
    closeDocumentModal() {
      this.showCustomModal = false;
      this.urlToAdd = "";
      this.urlTitle = "";
    },
    closeModal() {
      this._updateModalVisibility();
    },
    beautifyDate(date) {
      return moment(date).format("lll");
    },
    _updateModalVisibility() {
      this.showFile = !this.showFile;
    },
    onInputClicked(event) {
      event.target.value = "";
    },
    onFilePicked(event) {
      this.filesToUpload = [...this.filesToUpload, ...event.target.files];
      this.showCustomModal = false;
    },
    uploadDocument() {
      this.upload_status = "uploading";
      this.$emit("upload-document", {
        files: this.filesToUpload,
      });
    },
    uploadGoogleDriveDocument() {
      this.$emit("upload-document", {
        url: this.urlToAdd,
        title: this.urlTitle,
        is_external_url: true,
      });
    },
    removeFileUpload(index) {
      if (index > -1) {
        this.filesToUpload.splice(index, 1);
      }
    },
    userFormatter(uploader) {
      return uploader.firstname + " " + uploader.lastname;
    },
    resetUpload() {
      this.filesToUpload = [];
      this.upload_status = "idle";
    },
    downloadAction(file) {
      window.open(file || this.selectedFile.file, "_blank");
    },
    deleteDocument(fileId) {
      this.$emit("delete-document", fileId);
      if (this.showFile) {
        this.closeModal();
      }
    },
    can(name) {
      return permissions_utils.can(name);
    },
  },
};
</script>

<style lang="scss">
.report-document-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .file-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: $ui-background;
    padding: 8px;
    border-radius: 8px;
    width: 100%;

    .icon-container {
      background-color: $ui-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 6px;
      i {
        font-size: 16px;
        color: $ui-white;
      }
    }

    span {
      color: $ui-text;
      font-family: "Campton-Medium";
    }
  }

  .separator {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $ui-light_grey;
    line-height: 0.1em;
    margin: 20px 0;

    span {
      background: $ui-white;
      font-family: "Campton-Medium";
      padding: 0 10px;
    }
  }

  .url-input {
    width: 100%;
  }

  .report-document-modal-footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
  }
}
.files-to-upload-container {
  background-color: #5f9e831a;
  padding: 15px 10px;
  border-radius: 10px;
  margin-bottom: 25px;
  .upload-button {
    margin-top: 10px;
  }

  .files-to-upload--uploading {
    text-align: center;
    padding: 10px;
    color: $ui-text;
    .files-to-upload--uploading-text {
      font-size: 19px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
</style>
