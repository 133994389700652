<template>
  <div class="avatar-base-name">
    <avatar style="flex-shrink: 0" :name="name" :id="id" />
    <div class="avatar-base-name--name">
      <p class="avatar-base-name--name-title">{{ name }}</p>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/ui-modules/Avatar/Base";

export default {
  name: "AvatarBaseName",
  props: {
    name: {
      type: String,
    },
    id: {
      type: Number,
    },
    styles: {
      type: Object,
    },
  },
  components: {
    Avatar,
  },
};
</script>

<style lang="scss" scoped>
.avatar-base-name {
  display: flex;
  flex-direction: row;

  .avatar-base-name--name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 10px;

    .avatar-base-name--name-title {
      color: $ui-text;
      font-family: "Campton-Medium";
      margin: 0;
    }
  }
}
</style>
