<template>
  <ul class="score-search-list">
    <text-category-cell
      v-for="(result, index) in searchResults"
      :key="index"
      :color="result.color"
      :text="result.value"
      v-on:select="$emit('select', result)"
    />
  </ul>
</template>

<script>
import TextCategoryCell from "../CategoryCell/TextCategoryCell.vue";

import report_utils from "@/utils/report_utils";

export default {
  name: "ScoreSearchList",
  components: {
    TextCategoryCell,
  },
  props: {
    categorySelected: {
      type: Object,
    },
    searchQuery: {
      type: String,
      default: "",
    },
    searchQueries: {
      type: Array,
    },
  },
  data() {
    return {
      data: this.setupData(),
    };
  },
  computed: {
    searchResults() {
      return this.data.filter((element) => {
        return element["value"]
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    setupData() {
      let score_list = report_utils.getScores();
      let data = [];

      let added_score = this.searchQueries
        .filter((query) => query.category.type === "score")
        .map((e) => parseInt(e.value.id));

      score_list.forEach((score) => {
        if (!added_score.includes(score.score)) {
          data.push({
            id: score.score,
            value: this.$t(score.title),
            color: score.color,
          });
        }
      });

      return data;
    },
    fetchData() {},
    selectCell(item) {},
  },
};
</script>

<style></style>
