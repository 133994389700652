import axios from "axios";
import store from "../store/index";
import router from "../router/index";
import i18n from "@/utils/i18n/i18n";
var qs = require("qs");

const default_header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const request = (url) => {
  let api_call = axios.create({
    baseURL: url,
    headers: {
      ...default_header,
      "Accept-Language": i18n.t("language"),
    },
  });
  return api_call.get(url);
};

const request_logged = (url, parameters) => {
  let api_call = axios.create({
    headers: {
      ...default_header,
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": parameters?.language || i18n.t("language"),
    },
  });
  api_call.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err.response?.status === 401 && !url.includes("analytics")) {
        store.dispatch("logout");
        router.replace({ path: "login" });
      }
      return Promise.reject(err);
    },
  );
  return api_call.get(url, { params: parameters });
};

const request_logged_blob = (url, parameters) => {
  let api_call = axios.create({
    headers: {
      ...default_header,
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": parameters?.language || i18n.t("language"),
    },
    responseType: "blob",
  });
  api_call.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return Promise.reject(err);
    },
  );
  return api_call.get(url, { params: parameters });
};

const request_logged_html = (url) => {
  let new_url = url;
  return axios.create({
    baseURL: new_url,
    headers: {
      ...default_header,
      "Content-Type": "text/html",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_delete = (url, parameters) => {
  return axios.delete(url, {
    params: parameters,
    headers: {
      ...default_header,
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_put = (url, parameters) => {
  return axios.put(url, qs.stringify(parameters), {
    headers: {
      ...default_header,
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": parameters?.language || i18n.t("language"),
    },
  });
};

const request_logged_put_json = (url, parameters) => {
  return axios.put(url, JSON.stringify(parameters), {
    headers: {
      ...default_header,
      "Content-Type": "application/json",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_patch = (url, parameters) => {
  return axios.patch(url, qs.stringify(parameters), {
    headers: {
      ...default_header,
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_post = (url, parameters) => {
  return axios.post(url, parameters, {
    headers: {
      ...default_header,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_post = (url, parameters) => {
  return axios.post(url, parameters, {
    headers: {
      ...default_header,
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_post_multipart = (url, parameters) => {
  return axios.post(url, parameters, {
    headers: {
      ...default_header,
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + store.state.auth.token,
      "Accept-Language": i18n.t("language"),
    },
  });
};

const request_logged_post_multipart_progress = (
  url,
  parameters,
  onUploadProgress,
) => {
  return axios.post(url, parameters, {
    onUploadProgress: onUploadProgress,
    headers: {
      ...default_header,
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + store.state.auth.token,
    },
  });
};

const getErrorMessage = (error) => {
  if (error !== null && error.response !== null && error.response.data !== null)
    var err = error.response.data;
  if (typeof err.message === "string") {
    return err.message;
  } else if (err.message.constructor === Object) {
    var message = "";
    Object.keys(err.message).forEach(function (key) {
      message += err.message[key] + " ";
    });
    return message;
  } else {
    return "Error";
  }
};

const getMessage = (response) => {
  if (response !== null && response.data !== null) var resp = response.data;
  if (typeof resp.message === "string") {
    return resp.message;
  } else if (resp.message.constructor === Object) {
    var message = "";
    Object.keys(resp.message).forEach(function (key) {
      message += resp.message[key] + " ";
    });
    return message;
  } else {
    return "";
  }
};

export default {
  request,
  request_logged,
  request_logged_blob,
  request_logged_html,
  request_logged_delete,
  request_logged_put,
  request_logged_put_json,
  request_logged_patch,
  request_logged_post,
  request_logged_post_multipart,
  request_logged_post_multipart_progress,
  request_post,
  getErrorMessage,
  getMessage,
};
