<template>
  <dropdown-container :showDropdown="showDropdown" :positionX="positionX">
    <ul class="category-list">
      <li
        class="category-list-item"
        v-for="(item, index) in categoriesFiltered"
        v-bind:key="index"
        @click="$emit('select', item)"
      >
        <i :class="['fas', `fa-${item.icon}`, 'fa-icon']" />{{ $t(item.title) }}
      </li>
    </ul>
  </dropdown-container>
</template>

<script>
import DropdownContainer from "../DropdownContainer";

export default {
  name: "DropdownCategoryContainer",
  components: {
    DropdownContainer,
  },
  props: {
    showDropdown: {
      type: Boolean,
      default: false,
    },
    positionX: {
      type: Number,
    },
    categoriesFiltered: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
.category-list {
  margin: 0;
  padding: 10px 0px;
  list-style: none;
  .category-list-item {
    margin: 0px;
    text-align: left;
    padding: 5px 14px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    text-transform: capitalize;
    cursor: pointer;
    transition: ease-in-out 0.25s;
    color: $ui-text;
    font-family: "Campton-Medium";
    font-size: 15px;
    &:hover {
      background: $ui-lighter_grey;
    }
    .fa-icon {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 26px;
    }
  }
}
</style>
