<template>
  <li class="user-category-cell" @click="$emit('select')">
    <div class="user-category-cell-content">
      <avatar-name
        :name="user.firstname + ' ' + user.lastname"
        :id="user.id"
        :size="32"
      />
      <div class="user-category-texts">
        <p class="user-name">{{ user.firstname + " " + user.lastname }}</p>
        <div class="user-info-container">
          <p class="user-role">{{ user.job_title }}</p>
          <ui-tag
            style="text-transform: capitalize"
            :context="getRoleColorName(user.role)"
            subtle
            circle
            small
          >
            {{ $t(user.role) }}
          </ui-tag>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import AvatarName from "@/components/ui-modules/Avatar/Base";
import UiTag from "@/components/ui-modules/Tag";
import report_utils from "@/utils/report_utils";

export default {
  name: "TextCategoryCell",
  components: {
    AvatarName,
    UiTag,
  },
  props: {
    user: {
      type: Object,
    },
  },
  methods: {
    getRoleColorName(role) {
      return report_utils.getRoleColorName(role);
    },
  },
};
</script>

<style lang="scss">
.user-category-cell {
  padding: 6px 6px 6px 12px;
  cursor: pointer;
  &:hover {
    background: $ui-lighter_grey;
  }
  .user-category-cell-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .user-category-texts {
    .user-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
    .user-name,
    .user-role {
      margin: 0px;
      padding: 0px;
      text-align: left;
      font-family: "Campton-Medium";
    }
    .user-name {
      font-size: 15px;
      text-transform: capitalize;
    }
    .user-role {
      font-size: 14px;
      color: $ui-subtitle;
    }
  }
}
</style>
