<template>
  <ul class="tag-search-list">
    <text-category-cell
      v-for="(result, index) in searchResults"
      :key="index"
      :color_hex="result.color_hex"
      :text="result.value"
      v-on:select="$emit('select', result)"
    />
  </ul>
</template>

<script>
import TextCategoryCell from "../CategoryCell/TextCategoryCell.vue";

import tag_utils from "@/utils/tag_utils";

export default {
  name: "TagSearchList",
  components: {
    TextCategoryCell,
  },
  props: {
    categorySelected: {
      type: Object,
    },
    searchQuery: {
      type: String,
      default: "",
    },
    searchQueries: {
      type: Array,
    },
  },
  data() {
    return {
      data: this.setupData(),
    };
  },
  computed: {
    searchResults() {
      return this.data.filter((element) => {
        return element["value"]
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    setupData() {
      let tags_list = tag_utils.tags;
      let data = [];

      let added_tags = this.searchQueries
        .filter((query) => query.category.type === "tag")
        .map((e) => e.value.id);

      tags_list.forEach((tag) => {
        if (!added_tags.includes(tag.slug)) {
          data.push({
            id: tag.slug,
            value: this.$t(`tags_info.${tag.slug}`),
            color_hex: tag.color,
          });
        }
      });

      console.log(data);

      return data;
    },
    fetchData() {},
    selectCell(item) {},
  },
};
</script>

<style></style>
