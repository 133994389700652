const supportedLanguages = [
  {
    locale: "en",
    name: "English",
  },
  {
    locale: "fr",
    name: "Français",
  },
  {
    locale: "es",
    name: "Español",
  },
  {
    locale: "de",
    name: "Deutsch",
  },
  {
    locale: "it",
    name: "Italiano",
  },
];

export const DEFAULT_LANGUAGE = "en";
export const FALLBACK_LANGUAGE = "en";
export const SUPPORTED_LANGUAGES = supportedLanguages.map((x) => x.locale);
export const SUPPORTED_LANGUAGES_HUMAN = supportedLanguages;
