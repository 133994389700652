<template>
  <li class="report-category-cell" @click="$emit('select')">
    <div class="report-category-cell-content">
      <div class="report-status-icon">
        <span style="position: relative">
          <i class="fas fa-clipboard report-icon" />
          <i
            class="fas fa-circle status-icon"
            :style="{ '--color': getStatusColor(report.status_id) }"
          />
        </span>
      </div>
      <div class="report-category-texts">
        <p class="report-info">
          #{{ report.id }} -
          {{
            report.user
              ? report.user.firstname + " " + report.user.lastname
              : $t("anonymous")
          }}
        </p>
        <p class="report-date">
          <i v-if="report.submitted_at" class="fas fa-paper-plane fa-fw"></i>
          {{ beautifyDate(report.submitted_at) }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import report_utils from "@/utils/report_utils";
export default {
  name: "ReportCategoryCell",
  props: {
    report: {
      type: Object,
    },
  },
  methods: {
    beautifyDate(date) {
      return this.moment(date).format("lll");
    },
    getStatusColor(status_id) {
      return report_utils.getStatusColor(Number(status_id));
    },
  },
};
</script>

<style lang="scss">
.report-category-cell {
  padding: 6px 6px 6px 8px;
  cursor: pointer;
  &:hover {
    background: $ui-lighter_grey;
  }
  .report-category-cell-content {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: center;
    .report-status-icon {
      .report-icon {
        display: inline-block;
        color: $ui-subtitle;
        font-size: 24px;
      }
      .status-icon {
        border: 3px solid $ui-card;
        border-radius: 10px;
        bottom: -4px;
        color: rgba(var(--color), 1);
        font-size: 8px;
        position: absolute;
        right: -8px;
      }
    }
    .report-category-texts {
      .report-info,
      .report-date {
        margin: 0px;
        text-align: left;
        font-family: "Campton-Medium";
      }
      .report-info {
        font-size: 15px;
      }
      .report-date {
        font-size: 14px;
        color: $ui-subtitle;
      }
    }
  }
}
</style>
