<template>
  <ui-tag
    v-if="
      (step.question.type === 0 ||
        step.question.type === 1 ||
        step.question.type === 4) &&
      (answer.answer.type === 0 || answer.answer.type === 1)
    "
    context="dark_blue"
    circle
  >
    {{ answer.answer.answer }}
  </ui-tag>
  <other v-else-if="answer.answer.type === 2" :answer="answer.answer.answer" />
  <dates v-else-if="step.question.type === 2" :answer="answer.answer.answer" />
  <places v-else-if="step.question.type === 3" :answer="answer.answer.answer" />
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";

import Dates from "./Dates";
import Places from "./Places";
import Other from "./Other";

export default {
  name: "Step",
  components: {
    UiTag,
    Dates,
    Places,
    Other,
  },
  props: {
    step: {
      type: Object,
      default: null,
    },
    answer: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>
