<template>
  <ul class="anonymous-search-list">
    <text-category-cell
      v-for="(result, index) in searchResults"
      :key="index"
      :text="result.value"
      v-on:select="$emit('select', result)"
    />
  </ul>
</template>

<script>
import TextCategoryCell from "../CategoryCell/TextCategoryCell.vue";

export default {
  name: "AnonymousSearchList",
  components: {
    TextCategoryCell,
  },
  props: {
    categorySelected: {
      type: Object,
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: this.setupData(),
    };
  },
  computed: {
    searchResults() {
      return this.data.filter((element) => {
        return element["value"]
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    setupData() {
      return [
        {
          id: true,
          value: this.$t("yes"),
        },
        {
          id: false,
          value: this.$t("no"),
        },
      ];
    },
    fetchData() {},
    selectCell(item) {},
  },
};
</script>

<style></style>
