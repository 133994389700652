import posthog from "posthog-js";
import env from "@/utils/env";

const identifyUser = (userId, email) => {
  posthog.identify(String(userId), { email });
};

const resetUser = () => {
  posthog.reset();
};

const capturePageView = () => {
  if (!env.isProd()) {
    console.log("Register pageview");
  }

  posthog.capture("$pageview");
};

export default { identifyUser, resetUser, capturePageView };
