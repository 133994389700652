<template>
  <div class="conclusions">
    <p class="title">
      {{ type === "report" ? $t("report_conclusion") : $t("accused_outcome") }}
    </p>

    <multiselect
      v-model="conclusions_selected"
      ref="multiselect"
      :options="sortedConclusions"
      :multiple="true"
      :hide-selected="false"
      :taggable="false"
      :placeholder="$t('search_outcome')"
      :closeOnSelect="false"
      :clear-on-select="false"
    >
      <span slot="noResult">
        {{
          $t(
            type === "report"
              ? "outcomes.report_outcome_not_found"
              : "outcomes.accused_outcome_not_found",
          )
        }}
      </span>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          {{ $t(getTranslationKey(props.option)) }}
        </div>
        <i v-if="isSelected(props.option)" class="fa fa-check" />
      </template>
    </multiselect>
    <div class="conclusions_selected">
      <ui-tag
        v-for="conclusion in sortedSelectedConclusions"
        :key="conclusion"
        context="dark_blue"
        subtle
        round
        small
      >
        {{ $t(getTranslationKey(conclusion)) }}
        <i
          class="fa fa-times tag-removal"
          tabindex="1"
          @click="removeTag(conclusion)"
        />
      </ui-tag>
    </div>
    <div
      v-if="sortedSelectedConclusions.length === 0"
      class="add-resolutions-prompt"
    >
      <p class="add-resolutions-prompt__title">
        {{ $t("outcomes.no_outcome") }}
      </p>
      <p class="add-resolutions-prompt__subtitle">
        {{ $t("outcomes.add_outcome") }}
      </p>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import UiTag from "@/components/ui-modules/Tag";

export default {
  name: "ReportConclusions",
  components: {
    Multiselect,
    UiTag,
  },
  data() {
    return {
      conclusions_selected: [],
      saved_conclusions: null,
    };
  },
  props: {
    conclusions: {
      type: Array,
    },
    report_conclusions: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  created() {
    this.conclusions_selected = [...this.conclusions].filter((conclusion) =>
      this.report_conclusions.some(
        (report_conclusion) => report_conclusion === conclusion,
      ),
    );
  },
  computed: {
    sortedConclusions() {
      return [...this.conclusions].sort((a, b) => {
        const isSelectedA = this.isSelected(a);
        const isSelectedB = this.isSelected(b);
        const translationA = this.$t(this.getTranslationKey(a)).toLowerCase();
        const translationB = this.$t(this.getTranslationKey(b)).toLowerCase();

        if (isSelectedA && !isSelectedB) {
          return -1; // a is selected and b is not, a should come first
        } else if (!isSelectedA && isSelectedB) {
          return 1; // b is selected and a is not, b should come first
        } else {
          return translationA.localeCompare(translationB); // Both are selected or unselected, sort alphabetically
        }
      });
    },
    sortedSelectedConclusions() {
      return [...this.conclusions_selected].sort((a, b) => {
        const translationA = this.$t(this.getTranslationKey(a)).toLowerCase();
        const translationB = this.$t(this.getTranslationKey(b)).toLowerCase();
        return translationA.localeCompare(translationB);
      });
    },
  },
  watch: {
    conclusions_selected(newVal) {
      if (this.type === "report") {
        this.$emit("updateConclusions", newVal);
      } else {
        this.$emit("updateAccusedOutcomes", newVal);
      }
    },
  },
  methods: {
    isSelected(slug) {
      return this.conclusions_selected.some(
        (conclusion_selected) => conclusion_selected === slug,
      );
    },
    getTranslationKey(conclusion) {
      if (typeof conclusion !== "string") {
        return "";
      }
      const prefix =
        this.type === "report" ? "outcomes.report" : "outcomes.accused";
      return `${prefix}.${conclusion}`;
    },
    removeTag(tag) {
      this.conclusions_selected = this.conclusions_selected.filter(
        (conclusion) => conclusion !== tag,
      );
    },
  },
};
</script>

<style lang="scss">
.conclusions {
  position: relative;
  height: 100%;
  .title {
    font-size: 14px;
    text-align: left;
    font-family: "Campton-Medium";
    margin-bottom: 6px;
  }

  .multiselect__content-wrapper {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-color: $ui-light_grey;
    -webkit-box-shadow: $widget-shadow;
    -moz-box-shadow: $widget-shadow;
    box-shadow: $widget-shadow;
    z-index: 20;
    max-height: 250px !important;
    ul {
      li {
        margin-top: 0px;
        cursor: pointer;
        .option__desc {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 15px;
          font-weight: 400;
          color: $ui-text;
          font-family: "Campton-Medium" !important;
        }
      }
    }
  }

  .multiselect__select {
    display: none;
  }

  .multiselect__tags {
    margin-top: 5px;
    padding: 5px 10px;
    border: 1px solid $ui-light_grey;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .multiselect__input,
    .multiselect__placeholder {
      color: $ui-text;
      background-color: inherit;
      margin: 5px 0;
      width: 90% !important;
    }

    .multiselect__tags-wrap {
      display: none;
    }
  }

  .multiselect__option {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: pre-wrap;
    color: $ui-text;
    padding: 8px;
    .option__left-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option__square {
        width: 10px;
        height: 10px;
        border-radius: 2.5px;
        background-color: var(--color);
      }
      .option__title {
        font-size: 15px;
        margin-left: 5px;
        font-weight: 400;
        color: $ui-text;
        font-family: "Campton-Medium" !important;
      }
    }
    &--highlight {
      background-color: $ui-light_grey;
    }
  }

  .multiselect__option--highlight {
    &:after {
      display: none;
    }
  }

  .multiselect__option--selected {
    &:after {
      display: none;
    }
  }

  .conclusions_selected {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    .notme-tag {
      white-space: nowrap;
      font-size: 13px;
      margin-right: 0px;
      .tag-removal {
        padding: 5px 2.5px 5px 5px;
        color: $ui-grey;
        font-size: 12px;
        cursor: pointer;
        z-index: 10;
      }
    }
  }

  .add-resolutions-prompt {
    margin-top: 20px;
    &__title {
      font-weight: bold;
      font-size: 17px;
      color: $ui-subtitle;
      font-family: "Campton-Bold";
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 15px;
      color: $ui-subtitle;
      font-family: "Campton-Medium";
    }
  }
}
</style>
