<template>
  <text-value :value="new_value ? new_value : value" />
</template>

<script>
import tag_utils from "@/utils/tag_utils";

import TextValue from "../TypeQueryValue/TextValue";

export default {
  name: "TagQueryValue",
  components: {
    TextValue,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      new_value: null,
    };
  },
  created() {
    if (this.value.value === undefined) {
      let id = this.value.id;
      this.new_value = { id: id };
      this.new_value.value = this.$t(`tags_info.${id}`);
      this.new_value.color_hex = tag_utils.getTagColor(id);
    }
  },
};
</script>

<style></style>
