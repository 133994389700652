<template>
  <div class="notme--text-area">
    <label v-if="label" class="notme--text-area--label">{{ label }}</label>
    <textarea
      class="text-area"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :rows="numberOfLines"
      :value="model"
      @input="$emit('update:model', $event.target.value)"
    />
    <label
      v-if="showLength"
      class="notme--text-area notme--text-area--right-label"
    >
      {{ model ? model.length : 0 }}/{{ maxLength }}
    </label>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    maxLength: {
      type: Number,
    },
    numberOfLines: {
      type: Number,
    },
    model: {
      type: String,
    },
    showLength: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.notme--text-area {
  flex-direction: column;
  margin-bottom: 16px;

  .notme--text-area--label {
    font-family: "Campton-Medium";
    font-size: 16px;
    color: $ui-text;
    margin-bottom: 0px;
    float: left;
  }

  .notme--text-area--right-label {
    font-family: "Campton-Medium";
    color: $ui-blue;
    font-size: 14px;
    float: right;
    margin-bottom: 0px;
    text-align: right;
  }

  .text-area {
    -webkit-appearance: none;
    outline: none;
    border-radius: 0px;
    border: 1px solid $ui-light_grey;
    background-color: #fff;
    padding: 10px;
    width: 100%;
    resize: none;
    &:focus {
      outline: none;
      border: 1px solid $ui-red;
    }
  }
}
</style>
