<template>
  <div class="col-md-12 col-lg-3 report-sidebar">
    <report-information class="d-none d-lg-block" :report="report" />
    <template v-if="can('update report company')">
      <div class="sidebar-line" />
      <report-companies
        :company="report.company"
        v-on:save-company="saveCompany"
      />
    </template>
    <template v-if="can('update assigned report') && isReportResolved()">
      <div class="sidebar-line" />
      <report-resolution
        :resolution="report.resolution"
        v-on:open-resolution="openResolutionModal"
      />
    </template>
    <template v-if="can('view report assignees')">
      <div class="sidebar-line" />
      <report-assignees
        :assignees="report.assignees"
        v-on:save-assignees="saveAssignees"
      />
    </template>
    <template v-if="can('view report tags')">
      <div class="sidebar-line" />
      <report-tags
        :tags="getTags()"
        v-on:save-tags="saveTags"
        :report_tags="report.tags"
      />
    </template>
    <template v-if="report.report_files">
      <template v-if="can('view report evidence')">
        <div class="sidebar-line" />
        <report-evidence
          :report_files="
            report.report_files.filter(
              (e) => !e.uploader || e.uploader.role === 'user',
            )
          "
        />
      </template>
      <template v-if="can('view report documents')">
        <div class="sidebar-line" />
        <report-document
          ref="reportDocument"
          :report_files="
            report.report_files.filter(
              (e) => e.uploader && e.uploader.role !== 'user',
            )
          "
          :report_status_id="report.status_id"
          :upload_progress="upload_progress"
          :isUrlUploading="isUrlUploading"
          v-on:upload-document="uploadDocument"
          v-on:delete-document="deleteDocument"
          @closeDocumentModal="closeDocumentModal"
        />
      </template>
      <template v-else>
        <div style="height: 65px" />
      </template>
    </template>
  </div>
</template>

<script>
import ReportInformation from "./ReportInformation";
import ReportAssignees from "./ReportAssignees";
import ReportCompanies from "./ReportCompanies";
import ReportEvidence from "./ReportEvidence";
import ReportDocument from "./ReportDocument";
import ReportTags from "./ReportTags";
import ReportResolution from "./ReportResolution";
import permissions_utils from "@/utils/permissions_utils";
import report_utils from "@/utils/report_utils";
import tag_utils from "@/utils/tag_utils";

export default {
  name: "ReportSidebar",
  components: {
    ReportInformation,
    ReportAssignees,
    ReportCompanies,
    ReportEvidence,
    ReportDocument,
    ReportTags,
    ReportResolution,
  },
  props: {
    report: {
      type: Object,
    },
    upload_progress: {
      type: Number,
    },
    isUrlUploading: {
      type: Boolean,
    },
  },
  methods: {
    saveAssignees(value) {
      this.$emit("save-assignees", value);
    },
    saveCompany(value) {
      this.$emit("save-company", value);
    },
    uploadDocument(value) {
      this.$emit("upload-document", value);
    },
    deleteDocument(value) {
      this.$emit("delete-document", value);
    },
    closeDocumentModal() {
      this.$refs.reportDocument.closeDocumentModal();
    },
    saveTags(value) {
      this.$emit("save-tags", value);
    },
    openResolutionModal() {
      this.$emit("open-resolution");
    },
    isReportResolved() {
      return report_utils.isReportStatus(this.report.status_id, 6);
    },
    can(name) {
      return permissions_utils.can(name);
    },
    getTags() {
      return tag_utils.tags;
    },
  },
};
</script>

<style lang="scss">
.report-sidebar {
  @media (max-width: 991px) {
    padding: 0px;
  }
  .sidebar-line {
    border-top: 1px solid $ui-light_grey;
    margin-bottom: 20px;
  }
}
</style>
