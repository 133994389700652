<template>
  <div class="sidebar">
    <sidebar-container>
      <template slot="menu">
        <div class="fixed-header">
          <router-link :to="defaultRoute" class="menu__logo">
            <img src="@/assets/logo-notme.png" class="notme-logo" alt="icon" />
          </router-link>
        </div>
        <div
          v-bind:class="[
            'menu',
            currentRoutes === settingsRoutes || currentRoutes === profileRoutes
              ? 'menu-setting-padding'
              : '',
          ]"
        >
          <ul>
            <sidebar-cell
              v-for="(item, index) in currentRoutes"
              :key="index"
              :to="{ name: item.name }"
              :color="item.color || ''"
              :add-separator="item.addSeparator"
              :title="item.title"
              :icon="item.icon"
            />
          </ul>
          <master-selector v-if="hasMasterSelector()" />
        </div>
        <div
          v-if="
            (currentRoutes === settingsRoutes ||
              currentRoutes === profileRoutes) &&
            userRole !== 'editor'
          "
          class="back-footer"
        >
          <ul class="menu">
            <sidebar-cell
              :to="{
                name:
                  defaultRouteName === 'index' ? 'overview' : defaultRouteName,
              }"
              color="#5f9e83"
              style="margin-top: 0px"
              title="pages.back_to_dashboard"
              icon="fa-arrow-left"
              :isSpecial="true"
            >
            </sidebar-cell>
          </ul>
        </div>
        <div class="fixed-footer">
          <user-dropdown />
        </div>
      </template>
    </sidebar-container>
  </div>
</template>

<script>
import kebabCase from "lodash/kebabCase";
import SidebarContainer from "./components/SidebarContainer";
import SidebarCell from "./components/SidebarCell";
import UserDropdown from "./components/UserDropdown";
import permissions_utils from "@/utils/permissions_utils";
import MasterSelector from "./components/MasterSelector";
import UiButton from "@/components/ui-modules/Buttons/Button";
import url_utils from "../../../utils/url_utils";

export default {
  name: "Menu",
  components: {
    SidebarContainer,
    SidebarCell,
    UserDropdown,
    MasterSelector,
    UiButton,
  },
  data() {
    return {
      dashboardRoutes: this.setupRoutes("dashboard"),
      settingsRoutes: this.setupRoutes("settings"),
      profileRoutes: this.setupRoutes("profile"),
      currentRoutes: null,
      userRole: this.getUserRole(),
      defaultRoute: this.getDefaultRoute(),
      defaultRouteName: this.getDefaultRoute().replace(/^\//, ""),
    };
  },
  watch: {
    $route(to, from) {
      this.updateSidebar();
    },
    currentRoutes(newVal, oldVal) {
      const newValueName = newVal[0]?.name
        ? newVal[0]?.name.replace("-", "/")
        : null;
      if (oldVal !== null) {
        if (
          oldVal[0]?.name !== newVal[0]?.name &&
          newValueName !== this.defaultRouteName &&
          newVal[0]?.name !== "overview"
        ) {
          this.$router.replace({
            name: this.currentRoutes[0]?.name,
          });
        }
      }
    },
  },
  created() {
    this.updateSidebar();
    if (this.$router.history.current.meta.requiresRedirect) {
      this.$router.replace({
        name: this.currentRoutes[0].name,
      });
    }
  },
  methods: {
    shouldAddRoute(route) {
      if (route.meta.sidebar) {
        let tmp = true;
        //Check if "only_role" variable is in router to allow to show a route in sidebar depending of user role
        if (
          route.meta.only_role &&
          route.meta.only_role === this.getUserRole()
        ) {
          return tmp;
        }
        route.meta.permissions.forEach((permission) => {
          if (Array.isArray(permission)) {
            let canGoPermissionArray = false;
            permission.forEach((perm) => {
              if (permissions_utils.can(perm)) {
                canGoPermissionArray = true;
              }
            });
            tmp = canGoPermissionArray;
          } else {
            if (!permissions_utils.can(permission)) {
              tmp = false;
            }
          }
        });
        return tmp;
      }
      return false;
    },
    setupRoutes(route) {
      let routes = [];
      let router = this.$router.options.routes.find(
        (element) => element.name === route,
      );
      if (router) {
        router.children.forEach((element) => {
          if (this.shouldAddRoute(element)) {
            routes.push({
              path: element.path,
              name: element.name,
              ...element.meta.sidebar,
            });
          }
        });
        return routes;
      }
      return [];
    },
    can(name) {
      return permissions_utils.can(name);
    },
    hasMasterSelector() {
      if (this.currentRoutes !== this.dashboardRoutes) {
        return false;
      }
      return (
        (this.can("view company subsidiaries") &&
          this.can("read subsidiary report")) ||
        this.can("invite subscribers to any company") ||
        this.userRole === "viewer"
      );
    },
    getDefaultRoute() {
      return permissions_utils.getDefaultRoute();
    },
    getUserRole() {
      return permissions_utils.getUserRole();
    },
    updateSidebar() {
      let route = this.$route;
      let section = route.path.split("/")[1];
      switch (section) {
        case "settings":
          this.currentRoutes = this.settingsRoutes;
          break;
        case "profile":
          this.currentRoutes = this.profileRoutes;
          break;
        default:
          this.currentRoutes = this.dashboardRoutes;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  .notme-logo {
    height: $sidebar-logo-height;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li:first-child {
      margin-top: 26px;
    }
  }

  .menu {
    overflow-x: hidden;
    height: calc(
      100vh -
        (
          #{$sidebar-footer-height} + #{$sidebar-logo-height} + 2 *
            #{$sidebar-logo-padding-top}
        )
    );
    position: fixed;
    width: $sidebar-width-expanded;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu__logo:hover {
    text-decoration: none;
  }

  .menu-setting-padding {
    height: calc(
      100vh -
        (
          #{$sidebar-footer-height} + #{$sidebar-logo-height} + 2 *
            #{$sidebar-logo-padding-top} + #{$sidebar-back-height}
        )
    );
  }

  .fixed-header {
    padding-top: $sidebar-logo-padding-top;
    padding-bottom: $sidebar-logo-padding-top;
    border-bottom: 1px solid $ui-light_grey;
  }

  .back-footer {
    position: absolute;
    background-color: $ui-card;
    height: $sidebar-back-height;
    width: 100%;
    bottom: $sidebar-footer-height;
  }

  .fixed-footer {
    position: absolute;
    -webkit-backdrop-filter: saturate(50%) blur(20px);
    backdrop-filter: saturate(50%) blur(20px);
    background-color: $ui-card;
    bottom: 0;
    width: 100%;
    height: $sidebar-footer-height;
  }
}
</style>
