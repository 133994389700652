const report_status = [
  {
    id: 0,
    title: "utils.report_status.created",
    color: "28, 120, 165",
    color_name: "light_blue",
  },
  {
    id: 1,
    title: "utils.report_status.in_progress",
    color: "31, 86, 136",
    color_name: "blue",
  },
  {
    id: 2,
    title: "utils.report_status.saved",
    color: "58, 110, 88",
    color_name: "dark_green",
  },
  {
    id: 3,
    title: "utils.report_status.submitted",
    color: "0, 27, 57",
    color_name: "dark_blue",
  },
  {
    id: 4,
    title: "utils.report_status.opened",
    color: "68, 19, 19",
    color_name: "dark_red",
  },
  {
    id: 5,
    title: "utils.report_status.under_review",
    color: "193, 71, 58",
    color_name: "red",
  },
  {
    id: 6,
    title: "utils.report_status.resolved",
    color: "95, 158, 131",
    color_name: "green",
  },
];

const report_score = [
  {
    score: 0,
    title: "low",
    color: "28, 120, 165",
    color_name: "light_blue",
  },
  {
    score: 1,
    title: "medium",
    color: "31, 86, 136",
    color_name: "blue",
  },
  {
    score: 2,
    title: "high",
    color: "193, 71, 58",
    color_name: "red",
  },
  {
    score: null,
    title: "no_score",
    color: "99, 116, 130",
    color_name: "grey",
  },
];

const role_list = [
  {
    role: "admin",
    color: "193, 71, 58",
    color_name: "red",
  },
  {
    role: "manager",
    color: "28, 120, 165",
    color_name: "light_blue",
  },
  {
    role: "subscriber",
    color: "0, 27, 57",
    color_name: "dark_blue",
  },
  {
    role: "editor",
    color: "95, 158, 131",
    color_name: "green",
  },
  {
    role: "viewer",
    color: "68, 19, 19",
    color_name: "dark_red",
  },
];

const post_type = [
  {
    id: 0,
    title: "article",
  },
  {
    id: 1,
    title: "instagram",
  },
  {
    id: 2,
    title: "facebook",
  },
  {
    id: 3,
    title: "twitter",
  },
  {
    id: 4,
    title: "blog",
  },
  {
    id: 5,
    title: "policy",
  },
  {
    id: 6,
    title: "resource",
  },
  {
    id: 7,
    title: "announcement",
  },
];

const step_list = [
  {
    id: 1,
    name: "When",
    color: "#1F5688",
    color_name: "blue-2",
  },
  {
    id: 2,
    name: "What",
    color: "#001B39",
    color_name: "blue",
  },
  {
    id: 3,
    name: "Reporter",
    color: "#1c78a5",
    color_name: "light-blue",
  },
  {
    id: 4,
    name: "Where",
    color: "#5f9e83",
    color_name: "green-2",
  },
  {
    id: 5,
    name: "Who",
    color: "#c1473a",
    color_name: "red",
  },
];

const getStatusTitle = (id) => {
  return report_status.find((element) => element.id === id).title;
};

const getStatusColor = (id) => {
  return report_status.find((element) => element.id === id).color;
};

const getStatusColorName = (id) => {
  return report_status.find((element) => element.id === id).color_name;
};

const getStatusList = () => {
  return report_status;
};

const getStatusIdTitleList = () => {
  let new_arr = [];
  report_status.forEach((element) => {
    if (element.id > 2) {
      new_arr.push({ id: element.id, title: element.title });
    }
  });
  return new_arr;
};

const getStatusOrganizationList = () => {
  return report_status.slice(3);
};

const getStatusReportList = () => {
  return [report_status[4], report_status[5]];
};

const getScoreTitle = (score) => {
  return report_score.find((element) => element.score === score).title;
};

const getScoreColor = (score) => {
  return report_score.find((element) => element.score === score).color;
};

const getScoreColorByTitle = (title) => {
  return report_score.find((element) => element.title === title).color;
};

const getScoreColorName = (score) => {
  return report_score.find((element) => element.score === score).color_name;
};

const getScores = () => {
  return report_score.slice(0, 3);
};

const getRoles = () => {
  return role_list;
};

const getRolesReverse = () => {
  return role_list.reverse();
};

const getRolesColor = (role) => {
  return role_list.find((element) => element.role === role).color;
};

const getRoleColorName = (role) => {
  return role_list.find((element) => element.role === role).color_name;
};

const getRoleIndex = (index) => {
  return role_list[index].role;
};

const getPostTypes = () => {
  return post_type;
};
const getPostTypeId = (title) => {
  return post_type.find((element) => element.title === title).id;
};

const getPostTypeTitle = (id) => {
  return post_type.find((element) => element.id === id).title;
};

const isReportFilled = (status_id) => {
  return status_id >= 3;
};

const isReportStatusUpdatable = (status_id) => {
  return status_id > 3;
};

const isReportUnsubmitted = (status_id) => {
  return status_id < 3;
};

const isReportStatus = (status_id, status) => {
  return status_id >= status;
};

const isReporterExecutive = (user, is_anonymous) => {
  return user && user?.role !== "user" && !is_anonymous;
};

const getStepColor = (step_id) => {
  return step_list.find((element) => element.id === step_id).color;
};

const getStepColorName = (step_id) => {
  return step_list.find((element) => element.id === step_id).color_name;
};

const getStep = (step_id) => {
  return step_list.find((element) => element.id === step_id);
};

const getFileTypeIcon = (type) => {
  switch (type) {
    case "image":
    case "image/gif":
    case "image/jpeg":
    case "image/png":
      return "fa-file-image";
    case "audio":
    case "audio/aac":
    case "audio/wav":
    case "audio/mpeg":
    case "audio/mp4":
    case "audio/ogg":
      return "fa-file-audio";
    case "video":
    case "video/x-msvideo":
    case "video/mpeg":
    case "video/mp4":
    case "video/ogg":
    case "video/quicktime":
    case "video/webm":
      return "fa-file-video";
    case "application/pdf":
      return "fa-file-pdf";
    case "application/msword":
    case "application/vnd.ms-word":
    case "application/vnd.oasis.opendocument.text":
    case "application/vnd.openxmlformatsfficedocument.wordprocessingml":
      return "fa-file-word";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformatsfficedocument.spreadsheetml":
    case "application/vnd.oasis.opendocument.spreadsheet":
      return "fa-file-excel";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformatsfficedocument.presentationml":
    case "application/vnd.oasis.opendocument.presentation":
      return "fa-file-powerpoint";
    case "text/plain":
      return "fa-file-alt";
    case "text/html":
    case "application/json":
    case "text/csv":
    case "text/css":
    case "text/html":
    case "text/javascript":
    case "application/javascript":
      return "fa-file-code";
    case "application/zip":
    case "application/gzip":
      return "fa-file-archive";
    case "text/plain":
    case "text/richtext":
    case "text/rtf":
      return "fa-file-text";
    default:
      return "fa-file";
  }
};

const getFileFormat = (file_type) => {
  switch (file_type) {
    case "aac":
    case "mid":
    case "midi":
    case "mp2":
    case "mp3":
    case "oga":
    case "wav":
    case "weba":
    case "ogx":
      return "audio";
    case "apng":
    case "bmp":
    case "gif":
    case "jpeg":
    case "jpg":
    case "png":
    case "svg":
    case "tif":
    case "tiff":
    case "webp":
    case "ico":
      return "image";
    case "avi":
    case "mp4":
    case "mpa":
    case "mpe":
    case "mpeg":
    case "ogv":
    case "webm":
    case "3gp":
    case "3gp2":
      return "video";
    case "pdf":
      return "pdf";
    case "doc":
    case "docx":
    case "odt":
      return "word";
    case "ods":
    case "xls":
    case "xlsx":
      return "excel";
    case "odp":
    case "ppt":
      return "powerpoint";
    case "txt":
    case "rtf":
    case "epub":
      return "text";
    case "css":
    case "csv":
    case "htm":
    case "html":
    case "js":
    case "json":
    case "ts":
    case "xhtml":
    case "xml":
    case "xul":
      return "code";
    case "zip":
    case "7z":
    case "tar":
    case "bz":
    case "bz2":
    case "jar":
    case "mpkg":
    case "rar":
      return "archive";
    default:
      return "document";
  }
};

const isImage = (file_type) => {
  return getFileFormat(file_type) === "image";
};

const getFileTypeFromURL = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export default {
  getStatusTitle,
  getStatusColor,
  getStatusColorName,
  getStatusList,
  getStatusIdTitleList,
  getStatusOrganizationList,
  getStatusReportList,
  getScoreTitle,
  getScoreColor,
  getScoreColorByTitle,
  getScoreColorName,
  getScores,
  getRoles,
  getRolesReverse,
  getRolesColor,
  getRoleColorName,
  getRoleIndex,
  getPostTypes,
  getPostTypeId,
  getPostTypeTitle,
  isReportFilled,
  isReportStatusUpdatable,
  isReportUnsubmitted,
  isReportStatus,
  isReporterExecutive,
  getStepColor,
  getStepColorName,
  getStep,
  getFileTypeIcon,
  getFileFormat,
  isImage,
  getFileTypeFromURL,
};
