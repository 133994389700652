<template>
  <report-card
    title="evidence"
    :count="report_files.length"
    v-on:action="$refs.file.click()"
  >
    <div v-if="report_files.length === 0" style="text-align: center">
      <h5 class="title">{{ $t("no_evidence") }}</h5>
      <p class="subtitle">{{ $t("add_evidence_explanation") }}</p>
    </div>
    <report-file
      v-for="(file, index) in report_files"
      :key="index"
      :report_file="file"
      v-on:preview-file="previewFile(file)"
      :is_evidence="true"
    />
    <file-preview-modal
      v-if="showFile"
      :selectedFile="selectedFile"
      :closeModal="closeModal"
      :downloadAction="downloadAction"
      :is_evidence="true"
    />
  </report-card>
</template>

<script>
import moment from "moment";

import ReportCard from "./ReportCard";
import ReportFile from "./ReportFile";
import report_utils from "@/utils/report_utils";
import FilePreviewModal from "@/components/FilePreviewModal.vue";

export default {
  name: "ReportDocument",
  components: {
    ReportCard,
    ReportFile,
    FilePreviewModal,
  },
  props: {
    report_files: {
      type: Array,
    },
  },
  data() {
    return {
      showFile: false,
      selectedFile: null,
    };
  },
  methods: {
    previewFile(file) {
      let file_type = report_utils.getFileTypeFromURL(file.file);
      if (report_utils.isImage(file_type)) {
        this.selectedFile = file;
        this._updateModalVisibility();
      } else {
        this.downloadAction(file.file);
      }
    },
    closeModal() {
      this._updateModalVisibility();
    },
    beautifyDate(date) {
      return moment(date).format("lll");
    },
    _updateModalVisibility() {
      this.showFile = !this.showFile;
    },
    userFormatter(uploader) {
      return uploader.firstname + " " + uploader.lastname;
    },
    downloadAction(file) {
      window.open(file || this.selectedFile.file, "_blank");
    },
  },
};
</script>

<style lang="scss"></style>
