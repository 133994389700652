<template>
  <custom-modal
    v-if="modalVisible"
    class="report-resolution-modal"
    @close="closeModal"
    title="report_resolution"
    :dismissable="false"
    :max_width="450"
  >
    <div class="report-resolution-modal__content">
      <div class="report-resolution-modal__header">
        <el-progress
          :percentage="progressBarPercentage"
          :show-text="false"
          color="#1f5688"
        />
        <div class="report-resolution-modal__steps-indicator">
          {{ currentStep }}/{{ totalSteps }}
        </div>
      </div>
      <div class="report-resolution-modal__body">
        <transition name="fade" mode="out-in">
          <component
            :is="currentStepComponent"
            @changeStep="onChangeStep"
            @updateConclusions="conclusionsData = $event"
            @updateConclusionsDetails="conclusionDetailsData = $event"
            @updateAccusedOutcomes="accusedOutcome = $event"
            @updateAccusedOutcomeDetails="accusedOutcomeDetails = $event"
            v-bind="currentStepProps"
            :key="currentStep"
          />
        </transition>
      </div>
      <div class="report-resolution-modal__footer">
        <ui-button
          v-if="currentStep > 1"
          class="report-resolution-modal__button"
          @click="onChangeStep(-1)"
          outline
          :disabled="isLoading"
        >
          {{ $t("prev") }}
        </ui-button>
        <ui-button
          class="report-resolution-modal__button"
          @click="currentStep < totalSteps ? onChangeStep(1) : submitReport()"
          :disabled="!isValidStep"
          :loading="isLoading"
        >
          {{ currentStep < totalSteps ? $t("next") : $t("submit") }}
        </ui-button>
      </div>
    </div>
  </custom-modal>
</template>

<script>
import CustomModal from "@/components/modal/CustomModal";
import UiButton from "@/components/ui-modules/Buttons/Button";
import resolution_utils from "@/utils/resolution_utils";
import ReportConclusions from "./ReportConclusions";
import ReportConclusionDetails from "./ReportConclusionDetails";
import ReportResolutionSummary from "./ReportResolutionSummary";

export default {
  name: "ReportResolutionModal",
  components: {
    CustomModal,
    UiButton,
    ReportConclusions,
    ReportConclusionDetails,
    ReportResolutionSummary,
  },
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentResolution: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
      conclusionsData: [],
      conclusionDetailsData: "",
      accusedOutcome: [],
      accusedOutcomeDetails: "",
    };
  },
  async created() {
    this.setResolutionData();
  },
  computed: {
    progressBarPercentage() {
      return (this.currentStep / this.totalSteps) * 100;
    },
    isValidStep() {
      switch (this.currentStep) {
        case 1:
          return this.conclusionsData.length > 0;
        case 2:
          return this.conclusionDetailsData.length > 0;
        case 3:
          return this.accusedOutcome.length > 0;
        case 4:
          return this.accusedOutcomeDetails.length > 0;
        default:
          return true;
      }
    },
    currentStepComponent() {
      if (this.currentStep === 1 || this.currentStep === 3) {
        return "ReportConclusions";
      } else if (this.currentStep === 2 || this.currentStep === 4) {
        return "ReportConclusionDetails";
      } else {
        return "ReportResolutionSummary";
      }
    },
    currentStepProps() {
      switch (this.currentStep) {
        case 1:
          return {
            conclusions: resolution_utils.reportConclusions,
            report_conclusions: this.conclusionsData,
            type: "report",
          };
        case 2:
          return {
            conclusionDetails: this.conclusionDetailsData,
            type: "report",
          };
        case 3:
          return {
            conclusions: resolution_utils.accusedOutcomes,
            report_conclusions: this.accusedOutcome,
            type: "accused",
          };
        case 4:
          return {
            conclusionDetails: this.accusedOutcomeDetails,
            type: "accused",
          };
        default:
          return {
            conclusionsData: this.conclusionsData,
            conclusionDetailsData: this.conclusionDetailsData,
            accusedOutcome: this.accusedOutcome,
            accusedOutcomeDetails: this.accusedOutcomeDetails,
          };
      }
    },
  },
  watch: {
    modalVisible(newVal) {
      if (!newVal) {
        this.closeModal();
      }
    },
    currentResolution: {
      handler: "setResolutionData",
      deep: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.currentStep = 1;
    },
    onChangeStep(stepChange) {
      this.currentStep += stepChange;
    },
    submitReport() {
      this.$emit("save-resolution", {
        conclusionsData: this.conclusionsData,
        conclusionDetailsData: this.conclusionDetailsData,
        accusedOutcome: this.accusedOutcome,
        accusedOutcomeDetails: this.accusedOutcomeDetails,
      });
    },
    setResolutionData() {
      if (this.currentResolution) {
        this.conclusionsData = this.currentResolution.conclusionsData;
        this.conclusionDetailsData =
          this.currentResolution.conclusionDetailsData;
        this.accusedOutcome = this.currentResolution.accusedOutcome;
        this.accusedOutcomeDetails =
          this.currentResolution.accusedOutcomeDetails;
      }
    },
  },
};
</script>

<style lang="scss">
.report-resolution-modal {
  .custom-modal-wrapper {
    .custom-modal-container {
      background-color: $ui-background;
    }
  }
  &__content {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .custom-modal-header {
    h5 {
      text-align: left;
    }
  }

  &__body {
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
  }

  &__body::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    gap: 10px;
  }

  &__steps-indicator {
    font-weight: bold;
    text-align: right;
    margin-top: 5px;
    font-size: 12px;
    color: $ui-blue;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
