<template>
  <div class="sidebar">
    <top-widget
      :data_nb="count ? String(Math.round(count * 100)) : 'NA'"
      :data_title="$t('dashboard_sections.overview.reports_per_100_users')"
      color="#1f5688"
      data_icon="fa-clipboard"
    />
    <ui-loading v-if="loading_anonymous_reports" big context="blue" />
    <chart v-else-if="anonymous_reports.data" :data="anonymous_reports" />
    <top-widget
      v-if="reports"
      :data_nb="String(reports)"
      :data_title="`${$t('dashboard_sections.overview.unassigned_reports')}`"
      color="#441313"
      to="reports?has_assignee=false&sort_by=submitted_at&order_by=desc"
      data_icon="fa-user-tag"
    />
    <div class="sidebar-header">
      <h5 class="sidebar-title">
        {{ $t("dashboard_sections.overview.dashboard_users") }}
      </h5>
    </div>
    <ui-loading v-if="loading_dashboard_users" big context="blue" />
    <div v-else class="users-container">
      <div v-for="user in users" :key="user.id" class="avatar-container">
        <router-link :to="{ path: '/reports?assignee_id=' + user.id }">
          <avatar-name
            :name="`${user.firstname} ${user.lastname}`"
            :id="user.id"
            :size="44"
            :counter="user.assigned_reports"
            :showTooltip="true"
          />
        </router-link>
      </div>
    </div>
    <top-widget
      v-if="all_active_reports"
      :data_nb="String(all_active_reports)"
      :data_title="`${$t('dashboard_sections.overview.active_reports')}`"
      color="#3A6E58"
      to="reports?status_ids=3&status_ids=4&status_ids=5"
      data_icon="fa-clipboard-list"
    />
    <div class="report-aging-container">
      <chart v-if="report_aging.data" :data="report_aging" />
    </div>
  </div>
</template>

<script>
import TopWidget from "../TopWidget";
import ReportCellMini from "./components/ReportCellMini";
import AvatarName from "@/components/ui-modules/Avatar/Base";
import Chart from "../Chart.vue";
import UiLoading from "@/components/ui-modules/Loading";

export default {
  name: "OverviewSidebar",
  components: {
    TopWidget,
    AvatarName,
    ReportCellMini,
    Chart,
    UiLoading,
  },
  props: {
    title: {
      type: String,
    },
    users: {
      type: Array,
    },
    reports: {
      type: Number,
    },
    count: {
      type: Number,
    },
    report_aging: {
      type: Object,
    },
    all_active_reports: {
      type: Number,
    },
    anonymous_reports: {
      type: Object,
    },
    loading_anonymous_reports: {
      type: Boolean,
    },
    loading_dashboard_users: {
      type: Boolean,
    },
    loading_reports: {
      type: Boolean,
    },
  },
  data() {
    return {
      dataCharts: [],
    };
  },
  created() {
    this.dataCharts = this.setupChartsData();
  },
  methods: {
    setupChartsData() {
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 100%;
  .top-widget {
    padding-bottom: 10px;
  }
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    a {
      height: min-content;
    }
    p {
      margin-bottom: 0px;
      font-family: "Campton-Medium";
      color: $ui-blue;
      font-size: 14px;
    }
  }
  .sidebar-title {
    margin-bottom: 0px;
    text-align: left;
    font-size: 17px;
  }
  .users-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 100%;
    padding-top: 10px;
    gap: 10px;
    .avatar-container {
      padding-bottom: 10px;
      .notme-avatar {
        border-radius: 8px;
        margin-left: 0px;
        .notme-avatar-text {
          top: 0px !important;
        }
      }
    }
  }
  .reports-container {
    padding-top: 10px;
  }
  .report-aging-container {
    padding-bottom: 30px;
  }
}
</style>
