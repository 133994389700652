<template>
  <div class="report-header">
    <div class="header-bottom">
      <div class="header">
        <div class="header-title">
          <h4 class="title">{{ $t("report_noun") }}</h4>
          <h5 class="report-id">#{{ report.id }}</h5>
          <information-popover
            class="report-information"
            descriptionLink="https://help.not-me.com/reports-screen-video-individual"
          >
            <i class="fas fa-info-circle info-circle" />
          </information-popover>
          <div>
            <ui-tag
              v-if="
                can('view report score') && isReportFilled(report.status_id)
              "
              :context="getScoreColorName(report.score)"
              round
            >
              {{ $t(getScoreTitle(report.score)) }}
            </ui-tag>
            <el-dropdown
              v-if="
                can('update assigned report') &&
                isReportStatusUpdatable(report.status_id)
              "
              trigger="click"
              @command="handleCommand"
            >
              <ui-tag
                :context="getStatusColorName(report.status_id)"
                round
                clickable
              >
                {{ $t(getStatusTitle(report.status_id)) }}
                <i class="fas fa-chevron-down"></i>
              </ui-tag>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in this.getStatusList()"
                  :command="index"
                  :key="index"
                  >{{ $t(item.title) }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <ui-tag
              v-else
              :context="getStatusColorName(report.status_id)"
              round
            >
              {{ $t(getStatusTitle(report.status_id)) }}
            </ui-tag>
          </div>
        </div>
        <div class="right-content">
          <slot />
        </div>
      </div>
      <div class="header-report-info-bar d-block d-lg-none">
        <ul class="header-report-info-bar--list">
          <li class="header-report-info-bar--text">
            <i class="fas fa-user fa-fw"></i>
            {{ userFormatter() }}
            <el-tooltip
              effect="dark"
              placement="bottom"
              v-if="isReporterExecutive()"
            >
              <div slot="content">
                {{ $t("executive_report") }}
              </div>
              <i class="fas fa-id-badge fa-executive"></i>
            </el-tooltip>
          </li>
          <li class="header-report-info-bar--text" v-if="report.created_at">
            <i class="fas fa-calendar-alt fa-fw"></i>
            {{ beautifyDate(report.created_at) }}
          </li>
          <li class="header-report-info-bar--text" v-if="report.submitted_at">
            <i class="fas fa-paper-plane fa-fw"></i>
            {{ beautifyDate(report.submitted_at) }}
          </li>
          <li class="header-report-info-bar--text" v-if="report.resolved_at">
            <i class="fas fa-clipboard-check fa-fw"></i>
            {{ this.beautifyDate(report.resolved_at) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import UiTag from "@/components/ui-modules/Tag";
import InformationPopover from "@/components/InformationPopover";

import moment from "moment";

import report_utils from "@/utils/report_utils";
import permissions_utils from "@/utils/permissions_utils";

export default {
  name: "ReportHeader",
  components: {
    InformationPopover,
    UiTag,
  },
  props: {
    report: {
      type: Object,
      default: null,
    },
  },
  methods: {
    can(name) {
      return permissions_utils.can(name);
    },
    getScoreTitle(score_id) {
      return report_utils.getScoreTitle(score_id);
    },
    getScoreColorName(score_id) {
      return report_utils.getScoreColorName(score_id);
    },
    getStatusTitle(status_id) {
      return report_utils.getStatusTitle(status_id);
    },
    getStatusColorName(status_id) {
      return report_utils.getStatusColorName(status_id);
    },
    isReportFilled(status_id) {
      return report_utils.isReportFilled(status_id);
    },
    isReportStatusUpdatable(status_id) {
      return report_utils.isReportStatusUpdatable(status_id);
    },
    getStatusList() {
      return report_utils.getStatusReportList();
    },
    handleCommand(command) {
      let new_status = command + 4;
      if (new_status !== this.report.status_id) {
        this.$emit("update-report-status", new_status);
      }
    },
    userFormatter() {
      if (!this.report.is_anonymous && this.report.user) {
        return this.report.user.firstname + " " + this.report.user.lastname;
      }
      return this.$t("anonymous");
    },
    isReporterExecutive() {
      return report_utils.isReporterExecutive(
        this.report?.user,
        this.report.is_anonymous,
      );
    },
    beautifyDate(date) {
      return moment(date).format("lll");
    },
  },
};
</script>

<style lang="scss" scoped>
.report-header {
  .header {
    text-align: left;
    @media (min-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .header-title {
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .title {
      text-transform: capitalize;
      color: $ui-text;
      margin: 0px;
      font-family: "Campton-Medium";
      font-size: 32px;
    }

    .report-id {
      color: $ui-text;
      margin: 0px;
      font-weight: 500;
      font-size: 32px;
    }

    .report-information {
      color: $ui-dark_grey;
      margin: 0px 20px 0px 10px;
    }

    .right-content {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0.5rem;

      @media (max-width: 576px) {
        margin-bottom: 15px;
        display: block;
      }
    }
  }

  .header-report-info-bar {
    text-align: left;
    margin-bottom: 5px;
    .header-report-info-bar--list {
      overflow: hidden;
      padding: 0px;
      .fa-executive {
        color: $ui-light_blue;
        margin-left: 2px;
      }
    }
    .header-report-info-bar--text {
      @media (max-width: 576px) {
        display: block;
        margin-bottom: 5px;
      }
      display: inline;
      margin: 0;
      margin-right: 15px;
      color: $ui-subtitle;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .header-bottom {
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
    @media (min-width: 769px) and (max-width: 991px) {
      margin-bottom: 15px;
    }
    margin-bottom: $layout-padding-size;
    border-bottom: 1px solid $ui-light_grey;
  }
}
</style>
