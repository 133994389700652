<template>
  <el-popover
    :disabled="!description && !descriptionLink"
    placement="bottom"
    popper-class="information-popover"
    width="300"
    trigger="hover"
  >
    <p class="popover-text">{{ $t(description) }}</p>
    <i18n v-if="descriptionLink" path="pages.more_info_link" tag="p">
      <template v-slot:description_link>
        <a :href="descriptionLink" target="_blank">{{
          $t("pages.help_center")
        }}</a>
      </template>
    </i18n>
    <div
      slot="reference"
      v-bind:style="[isMaxHeight ? { height: '100%' } : {}]"
    >
      <slot />
    </div>
  </el-popover>
</template>

<script>
export default {
  name: "InformationPopover",
  props: {
    description: {
      type: String,
    },
    descriptionLink: {
      type: String,
      default: null,
    },
    isMaxHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.information-popover {
  border-radius: $widget-radius;
  border: none;
  -webkit-box-shadow: $widget-shadow;
  -moz-box-shadow: $widget-shadow;
  box-shadow: $widget-shadow;
  font-family: "Campton-Book";
  color: $ui-text;

  p {
    margin: 0;
    word-break: normal;
    text-align: left;
  }
}
</style>
