import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./languages/en.json";
import fr from "./languages/fr.json";
import es from "./languages/es.json";
import it from "./languages/it.json";
import de from "./languages/de.json";

import enElementLocale from "element-ui/lib/locale/lang/en";
import frElementLocale from "element-ui/lib/locale/lang/fr";
import esElementLocale from "element-ui/lib/locale/lang/es";
import itElementLocale from "element-ui/lib/locale/lang/it";
import deElementLocale from "element-ui/lib/locale/lang/de";

Vue.use(VueI18n);

const messages = {
  en: { ...en, ...enElementLocale },
  fr: { ...fr, ...frElementLocale },
  es: { ...es, ...esElementLocale },
  it: { ...it, ...itElementLocale },
  de: { ...de, ...deElementLocale },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "fr",
  messages,
  silentTranslationWarn: process.env.NODE_ENV === "production",
});

export default i18n;
