<template>
  <authentication-layout
    :title="$t('authentication_section.forgot_password')"
    :subtitle="$t('authentication_section.forgot_password_description')"
    class="forgot-password-container"
  >
    <div class="forgot-password">
      <ui-input
        :model.sync="emailAddress"
        :onEnterPressed="resetPassword"
        type="email"
        :label="$t('user.email_address')"
      />
    </div>
    <div class="forgot-password-buttons">
      <ui-button
        :disabled="!isFormValid"
        :loading="isLoading"
        full
        @click="resetPassword"
        >{{
          isLoading
            ? $t("authentication_section.sending")
            : $t("authentication_section.send_email")
        }}
      </ui-button>
      <ui-button :disbled="isLoading" outline full @click="cancelMethod"
        >{{ $t("cancel") }}
      </ui-button>
    </div>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from "@/layouts/AuthenticationLayout";
import UiInput from "@/components/ui-modules/Input";
import Selector from "@/components/ui-modules/Selector";
import UiButton from "@/components/ui-modules/Buttons/Button";
import url_utils from "@/utils/url_utils";
import userApi from "@/api/userApi";
import api from "@/api/api";

export default {
  name: "ForgotPassword",
  components: {
    AuthenticationLayout,
    UiInput,
    Selector,
    UiButton,
  },
  data() {
    return {
      isLoading: false,
      emailAddress: "",
    };
  },
  computed: {
    isFormValid() {
      return (
        this.emailAddress &&
        this.emailAddress !== "" &&
        url_utils.checkEmail(this.emailAddress)
      );
    },
  },
  methods: {
    resetPassword() {
      this.isLoading = true;
      userApi
        .postRecover(this.emailAddress)
        .then((response) => {
          if (response.status === 200) {
            this.isLoading = !this.isLoading;
            this.$message({
              message: api.getMessage(response),
              type: "success",
            });
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.$message({
            message: api.getErrorMessage(err),
            type: "error",
          });
          this.isLoading = !this.isLoading;
        });
    },
    cancelMethod() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.forgot-password-container {
  .forgot-password {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 10px;
  }
  .forgot-password-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
