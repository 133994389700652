<template>
  <div class="accused">
    <dashboard-header
      title="pages.dashboard.accused"
      description="dashboard_sections.accused.description"
      descriptionLink="https://help.not-me.com/accused-page"
    >
      <dropdown-button
        :content="sort_filters"
        :current_index="current_sort_filter_index"
        :on-click="handle_sort_command"
        title="sort_by"
        element_title="title"
      />
      <button-arrow
        :value="show_filters"
        :on-click="showDropdown"
        title="filters"
      />
    </dashboard-header>
    <transition name="slide">
      <div v-if="show_filters" class="row row-filters">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <a v-if="value !== ''" class="clear-filter" @click="clearDates">{{
            $t("clear")
          }}</a>
          <h6>{{ $t("date_range") }}</h6>
          <el-select
            v-model="value"
            filterable
            :placeholder="$t('select')"
            style="display: block; margin-bottom: 10px"
            @change="handle_date_range_command"
          >
            <el-option
              v-for="(item, index) in date_range"
              :key="index"
              :label="$t(item.value, { x: item.range })"
              :value="index"
            />
          </el-select>
        </div>
      </div>
    </transition>

    <div v-if="!this.isLoading">
      <div
        v-if="this.harassers !== null && this.harassers.length > 0"
        class="table-harassers"
      >
        <accused-cell
          v-for="(item, index) in harassers"
          :key="index"
          :accused="item"
        />
        <pagination-component
          :current_page="this.current_page"
          :limit="Number(this.query.limit)"
          :total="total"
          :handle-current-change="handleCurrentChange"
          color="#c1473a"
        />
      </div>
      <no-data-content v-else title="pages.dashboard.accused" />
    </div>
    <div v-loading.lock="isLoading" v-else class="loader-middle" />
  </div>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader";
import PaginationComponent from "@/components/PaginationComponent";
import NoDataContent from "@/components/NoDataContent";
import AccusedCell from "./components/AccusedCell";
import ButtonArrow from "@/components/ui-modules/Buttons/ButtonArrow";
import DropdownButton from "@/components/DropdownButton";
import url_utils from "@/utils/url_utils";
import permissions_utils from "@/utils/permissions_utils";
import moment from "moment";
import analyticsApi from "@/api/analyticsApi";

export default {
  name: "Accused",
  components: {
    DashboardHeader,
    PaginationComponent,
    NoDataContent,
    AccusedCell,
    ButtonArrow,
    DropdownButton,
  },
  data() {
    return {
      analytics_api_url: "",
      harassers: null,
      isLoading: false,
      current_page: 0,
      current_sort_filter_index: 0,
      current_date_range_index: 0,
      value: "",
      total: null,
      sort_filters: [
        {
          title: "reports_most_least",
          sort_by: "count",
          order_by: "desc",
        },
        {
          title: "reports_least_most",
          sort_by: "count",
          order_by: "asc",
        },
        {
          title: "alphabetical_az",
          sort_by: "name",
          order_by: "asc",
        },
        {
          title: "alphabetical_za",
          sort_by: "name",
          order_by: "desc",
        },
      ],
      date_range: [
        {
          value: "last_week",
          range: 1,
          type: "week",
        },
        {
          value: "last_x_weeks",
          range: 2,
          type: "week",
        },
        {
          value: "last_month",
          range: 1,
          type: "months",
        },
        {
          value: "last_x_months",
          range: 2,
          type: "months",
        },
        {
          value: "last_x_months",
          range: 3,
          type: "months",
        },
        {
          value: "last_x_months",
          range: 6,
          type: "months",
        },
        {
          value: "last_x_months",
          range: 12,
          type: "months",
        },
        {
          value: "all_time",
        },
      ],
      show_filters: false,
      query: {
        offset: 0,
        limit: 10,
        sort_by: "count",
        order_by: "desc",
        start_date: null,
        end_date: null,
      },
      initial_query: {
        offset: 0,
        limit: 10,
        sort_by: "count",
        order_by: "desc",
        start_date: null,
        end_date: null,
      },
    };
  },
  computed: {
    selected_company() {
      return this.$store.getters.getSelectedCompanies();
    },
  },
  watch: {
    $route(to, from) {
      if (
        from.name === to.name &&
        JSON.stringify(to.query) === JSON.stringify({})
      ) {
        this.current_page = 0;
        this.value = "";
        this.updateQuery(this.initial_query);
      } else {
        this.query = to.query;
        this.current_page = to.query.offset / to.query.limit + 1;
        this.getAccused();
      }
    },
    selected_company(newValue) {
      let newQuery = [];
      if (newValue) {
        newQuery = [...newValue];
      } else {
        newQuery = null;
      }
      this.updateQuery({
        company_ids: newQuery,
      });
    },
  },
  created() {
    this.analytics_api_url = url_utils.getEnvVariable("analytics_api_url");
    this.initRoute();
  },
  methods: {
    initRoute() {
      var queryTmp = this.$route.query;

      Object.keys(queryTmp).forEach(function (el) {
        if (queryTmp[el] && !isNaN(queryTmp[el])) {
          if (!Array.isArray(queryTmp[el])) {
            queryTmp[el] = parseInt(queryTmp[el]);
          }
        } else if (isNaN(queryTmp[el])) {
          queryTmp[el] = null;
        }
      });

      queryTmp = {
        ...queryTmp,
        ...url_utils.urlParamsToObject(window.location.search),
      };

      const selectedCompanies = this.$store.getters.getSelectedCompanies();
      if (selectedCompanies) {
        queryTmp.company_ids = [...selectedCompanies];
      } else {
        queryTmp.company_ids = null;
      }

      if (
        queryTmp.offset !== undefined &&
        queryTmp.offset !== null &&
        queryTmp !== this.query
      ) {
        this.query = queryTmp;
        this.current_page = queryTmp.offset / queryTmp.limit + 1;
      } else if (queryTmp !== this.query) {
        this.query = {
          ...this.query,
          ...queryTmp,
        };
        this.current_page = this.query.offset / this.query.limit + 1;
      }

      this.$router.replace({
        path: "accused",
        query: this.query,
      });
    },
    async getAccused() {
      this.isLoading = true;
      const cid =
        this.query.company_id ||
        JSON.parse(this.$store.state.auth.user).company.id;
      const isAdmin =
        Array.isArray(this.query.company_ids) &&
        this.query.company_ids.length > 0;
      const chartIds = [22];
      try {
        const response = await analyticsApi.getAnalyticsReport(
          this.analytics_api_url,
          cid,
          this.query.start_date,
          this.query.end_date,
          this.query.start_date ? "BETWEEN" : null,
          isAdmin,
          chartIds,
          this.query.company_ids,
          "JSON",
          this.query.sort_by,
          this.query.order_by,
          this.query.offset,
          this.query.limit,
        );

        if (response.data?.dataset) {
          this.harassers = response.data.dataset[0].data;
          this.total = response.data.dataset[0].page.total;
        }
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    updateQuery(query) {
      this.query = {
        ...this.query,
        ...query,
      };
      this.$router
        .push({
          path: "accused",
          query: this.query,
        })
        .catch(() => {});
    },
    showDropdown() {
      this.show_filters = !this.show_filters;
    },
    handle_sort_command(command) {
      this.current_page = 0;
      this.current_sort_filter_index = command;
      this.updateQuery({
        offset: 0,
        sort_by: this.sort_filters[this.current_sort_filter_index].sort_by,
        order_by: this.sort_filters[this.current_sort_filter_index].order_by,
      });
    },
    handle_date_range_command(command) {
      this.current_page = 0;
      this.current_date_range_index = command;
      if (this.date_range[command].range) {
        this.updateQuery({
          offset: 0,
          start_date: moment()
            .subtract(
              this.date_range[command].range,
              this.date_range[command].type,
            )
            .format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD"),
        });
      } else {
        this.updateQuery({
          offset: 0,
          start_date: null,
          end_date: null,
        });
      }
    },
    handleCurrentChange(val) {
      this.current_page = val;
      let offset = (val - 1) * Number(this.query.limit);
      this.updateQuery({ offset });
    },
    clearDates() {
      this.current_page = 0;
      this.value = "";
      this.updateQuery({
        offset: 0,
        start_date: null,
        end_date: null,
      });
    },
    can(name) {
      return permissions_utils.can(name);
    },
  },
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-in;
  max-height: 230px;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  max-height: 0px;
}

.accused {
  .row-filters {
    text-align: left;
    margin-bottom: 20px;

    .clear-filter {
      float: right;
      font-size: 12px;
      font-family: "Campton-Medium";
      padding-top: 2px;
      color: $ui-red;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: $ui-text;
      }
    }

    h6 {
      text-transform: uppercase;
      letter-spacing: 0.75px;
      color: #999;
      display: block;
      margin: 0 0 8px;
      font-size: 12px;
      font-family: "Campton-Medium";
      line-height: 1.5em;
    }
  }

  .loader-middle {
    top: 200px;
  }
}
</style>
