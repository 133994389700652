<template>
  <transition name="modal-transition">
    <div class="custom-modal">
      <div class="custom-modal-wrapper" @click="handleWrapperClick">
        <div
          class="custom-modal-container"
          :style="{ 'max-width': `${max_width}px` }"
          @click.stop
        >
          <div class="custom-modal-header">
            <a class="close-button" @click="$emit('close')">{{
              $t("close")
            }}</a>
            <h5>{{ $t(title) }}</h5>
          </div>
          <div class="custom-modal-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CustomModal",
  props: {
    title: {
      type: String,
    },
    max_width: {
      type: Number,
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleWrapperClick() {
      if (this.dismissable) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss">
.custom-modal {
  position: fixed;
  z-index: 2001 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: table;
  transition: opacity 0.3s ease;

  h5 {
    margin-top: 0px !important;
    padding-bottom: 5px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid $ui-light_grey !important;
  }

  .custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    overflow-y: initial !important;

    .custom-modal-container {
      background-color: $ui-card;
      margin: 10px auto;
      padding: 20px 0px;
      border-radius: $widget-radius;
      max-width: 400px;
      transition: all 0.3s ease;
      display: block;

      .close-button {
        float: right;
        font-size: 12px;
        font-family: "Campton-Medium";
        padding-top: 2px;
        color: $ui-red;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          color: $ui-text;
        }
      }

      .custom-modal-header {
        padding: 0px 20px;
      }

      .custom-modal-body {
        overflow-y: auto;
        height: auto;
        max-height: calc(100vh - 100px);
        padding: 0px 20px;
      }
    }
  }
}

.modal-transition-enter {
  opacity: 0;
}

.modal-transition-leave-active {
  opacity: 0;
}

.modal-transition-enter .custom-modal-container,
.modal-transition-leave-active .custom-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
