<template>
  <ul class="date-range-search-list">
    <text-category-cell
      v-for="(result, index) in searchResults"
      :key="index"
      :text="result.value"
      v-on:select="$emit('select', result)"
    />
  </ul>
</template>

<script>
import TextCategoryCell from "../CategoryCell/TextCategoryCell.vue";
import moment from "moment";

export default {
  name: "DateRangeSearchList",
  components: {
    TextCategoryCell,
  },
  props: {
    categorySelected: {
      type: Object,
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: this.setupData(),
    };
  },
  computed: {
    searchResults() {
      return this.data.filter((element) => {
        return element["value"]
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    setupData() {
      return [
        {
          id: this.getDate("days", 7),
          value: this.$t("last_week"),
        },
        {
          id: this.getDate("weeks", 2),
          value: this.$t("last_x_weeks", { x: 2 }),
        },
        {
          id: this.getDate("months", 1),
          value: this.$t("last_month"),
        },
        {
          id: this.getDate("months", 2),
          value: this.$t("last_x_months", { x: 2 }),
        },
        {
          id: this.getDate("months", 3),
          value: this.$t("last_x_months", { x: 3 }),
        },
        {
          id: this.getDate("months", 6),
          value: this.$t("last_x_months", { x: 6 }),
        },
        {
          id: this.getDate("months", 12),
          value: this.$t("last_x_months", { x: 12 }),
        },
        {
          id: null,
          value: this.$t("all_time"),
        },
      ];
    },
    fetchData() {},
    selectCell(item) {},
    getDate(type, range) {
      return moment().subtract(range, type).format("YYYY-MM-DD");
    },
  },
};
</script>

<style></style>
