<template>
  <el-dropdown trigger="click" @command="onClick">
    <ui-button context="blue" :loading="loading">
      <i class="fas fa-download report-dropdown-format-icon" />
      {{ $t("export_report") }}
      <i class="fas fa-chevron-down report-dropdown-format-arrow-icon" />
    </ui-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(item, index) in content"
        :command="item"
        :key="index"
      >
        {{ $t("export_to_format", { format: item.name }) }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import UiButton from "@/components/ui-modules/Buttons/Button";

export default {
  name: "ReportDropdownFormat",
  components: {
    UiButton,
  },
  props: {
    loading: {
      type: Boolean,
    },
    onClick: {
      type: Function,
    },
  },
  data() {
    return {
      content: [
        { name: "PDF", format: "pdf" },
        { name: "CSV", format: "csv" },
      ],
    };
  },
};
</script>

<style lang="scss">
.report-dropdown-format-icon {
  margin-right: 4px;
  font-size: 14px;
}
.report-dropdown-format-arrow-icon {
  margin-left: 4px;
  font-size: 14px;
}
</style>
